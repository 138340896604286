import { Heading1, Input, Button, Modal } from '@roc-digital/ui-web';

const NewsletterModal = () => {

  const signupAction = () => {
    console.log('Newsletter signup event');
    Modal.close('mxm.modal');
  };

  return (
    <div className="p-12 dark:bg-surface-dark">
      <Heading1 className="font-[Chivo] !font-bold text-3xl dark:text-white mb-8">Sign up for our daily digest</Heading1>
      <div className="mt-6">
        <Input
          type="email"
          // value={email}
          // changeEvent={handleEmailChange}
          placeholder="Email Address"
          className="w-full mb-3 px-3 py-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm font-worksans !text-base sm:pr-12"
        />
      </div>
      <div className="w-1/2 mx-auto mt-6">
        <Button clickEvent={() => signupAction()}>Sign Up</Button>
      </div>
    </div>
  );
};

export default NewsletterModal;
