import { publish } from '@roc-digital/mxm-base/events';

import { ClickEvent, CustomEvent, PublisherData } from '@roc-digital/ui-lib';
import { useEffect, useState } from 'react';
import { BodyDark } from './Typefaces';
import { IconV2 } from './IconV2';

export enum BadgeSizes {
  large = 'lg',
  medium = 'md',
  small = 'sm',
  xsmall = 'xs',
}

export type BadgeProps = {
  id?: string;
  size?: BadgeSizes;
  icon?: JSX.Element | string;
  image?: string;
  iconSize?: 'x-small' | 'small' | 'medium' | 'large' | 'x-large';
  fill?: string;
  stroke?: string;
  title?: string;
  selected?: boolean;
  customClassName?: string;
  customTextClassName?: string;
  customIconClassName?: string;
  clickEvent?: ClickEvent | CustomEvent | Function;
  // TODO: Fix badge so it accepts children or figure out from
  //       Ricardo why he assumed it accepted children
  children?: any;
};

const baseClasses = 'flex rounded-half dark:bg-surface-dark-muted items-center flex-row !font-workSans ';

const badgeClasses = {
  lg: 'px-4 py-2.5 w-full space-x-2',
  md: 'px-3 py-2 w-6/12 space-x-2',
  sm: 'px-2.5 py-1.5 w-fit space-x-2',
  xs: 'px-1 w-18',
};

const textClasses = {
  lg: 'text-lg',
  md: 'text-base',
  sm: 'text-sm leading-1 line-clamp-1',
  xs: 'text-xs leading-1 line-clamp-1',
};

export const Badge = ({
  id,
  size = BadgeSizes.large,
  image,
  title,
  selected: initSelected = false,
  customClassName = '',
  customTextClassName = '',
  clickEvent,
}: BadgeProps) => {
  const [selected, setSelected] = useState(initSelected);

  useEffect(() => {
    setSelected(initSelected);
  }, [initSelected]);

  const publishClick = (evt: React.MouseEvent<HTMLDivElement>) => {
    if (!clickEvent) {
      return;
    }

    if(typeof clickEvent === 'function') {
      return clickEvent();
    }

    if (clickEvent.allowDefault === false) {
      evt.preventDefault();
    }

    if (clickEvent.propagate === false) {
      evt.stopPropagation();
    }
    setSelected(!selected);

    publish<PublisherData>(clickEvent.namespace, 'click', {
      id,
      eventData: clickEvent.data || {},
    });
  };

  const badgeClassName = `${badgeClasses[size]} ${
    selected ? 'bg-badge-primary' : 'bg-badge dark:bg-surface-dark'
  } ${baseClasses} ${customClassName}`;

  const textClassName = `${selected ? 'text-white dark:text-font-muted' : 'text-admin-dark dark:text-white'} ${
    size === 'xs' && 'text-sm'
  } ${textClasses[size]} ${customTextClassName}`;
  
  return (
    <div id={id} className={badgeClassName} onClick={publishClick}>
      <IconV2
        src={image || ''}
        size={16}
        fill={`${selected ? '#FFFFFF' : '#919193'}`}
        stroke={`${selected ? '#FFFFFF' : '#919193'}`}
      />
      <BodyDark className={`${textClassName} !font-workSans pl-0 pr-1.5`}>{title}</BodyDark>
    </div>
  );
};

Badge.defaultProps = {
  type: 'default',
};
