import { Badge, BadgeSizes } from './Badge';
import { Image } from './Image';
import {
  upvoteIcon,
  upvoteFillIcon,
  downvoteIcon,
  downvoteFillIcon,
  bookmarkIcon,
  bookmarkFillIcon,
  shareIcon,
  ArticleViewProps,
  ArticleAction,
} from '@roc-digital/ui-lib';
import { Icon } from './Icon';
import { ArticleTitle, BodyDark } from './Typefaces';
import { Blurhash } from './Blurhash';


export function ArticleView(props: ArticleViewProps) {

  if(!props.article) {
    return <></>;
  }

  const miniImage = props.orientation === 'landscape' && props.size === 'sm' ? true : false;

  const showActions = () => {
    if(props.actions !== undefined && props.actions === false) {
      return false;
    } else {
      return true;
    }
  };

  return <Card
    noFrame={props.noFrame}
    className={props.className}
    orientation={props.orientation}
    header={miniImage === false ?  <ImageTest
      className={`bg-gray-200 h-[237px]`}
      height={237}
      src={props.article.image}
      blurhash={props.article?.blurhash}
      style={{ objectFit: 'cover' }}
      clickEvent={() => props.onAction && props.onAction({action: 'open', articleId: props.article.id})}
      mini={miniImage}
    /> : undefined}
  >
    <MetaBar
      logo={props.article.logo}
      timeLapse={props.article.timeLapse}
      tag={props.article.tag}
    />
    <ArticleHeadline
      size={props.size}
      headline={props.article.headline}
      image={miniImage ? props.article.image : undefined}
      blurhash={props.article?.blurhash}
      onClick={() => props.onAction && props.onAction({action: 'open', articleId: props.article.id})}
      children={props.children}
    />
    {showActions() ?
      <ActionBar
        bookmarked={props.article.bookmarked}
        voted={props.article.voted}
        upvotes={props.article.upvotes || 0}
        downvotes={props.article.downvotes || 0}
        articleId={props.article.id || ''}
        onAction={props.onAction || (() => {})}/>
    : null}
  </Card>;
}

interface MetaBarProps {
  logo?: string;
  timeLapse?: string;
  tag?: string;
}

function MetaBar(props: MetaBarProps) {
  return <div className='flex flex-row items-center gap-3 flex-wrap'>
    {props.logo && <Image className='!h-[30px] !w-auto' src={props.logo} style={{ maxWidth: '65px', maxHeight: '12px' }} />}
    <BodyDark className='text-gray-400 text-xs dark:text-white'>{props.timeLapse}</BodyDark>
    <div className="grow"></div>
    {props.tag && (
      <Badge
        type="web"
        size={BadgeSizes.xsmall}
        customClassName={'bg-badge rounded-full capitalize dark:bg-surface-dark-muted'}
        title={props.tag}
      />
    )}
  </div>
}

interface CardProps {
  children: any;
  orientation? : 'portrait' | 'landscape',
  header?: JSX.Element;
  className?: string;
  noFrame?: boolean;
}

function Card(props: CardProps) {
  const flexDirection = props.orientation === 'landscape' ? 'flex-row' : 'flex-col';
  let cardFrame = `shadow shadow-gray-300 bg-white rounded-sm`;
  let contentFrame = `p-4`;

  if(props.noFrame) {
    cardFrame = '';
    contentFrame = '';
  }

  if(props.header) {
    return <div className={`flex flex-wrap ${flexDirection} ${cardFrame} overflow-hidden dark:bg-surface-dark-low ${props.className || ''}`}>
      <div className='flex flex-1 flex-col bg-gray-200 justify-center bg-red-400' style={{minWidth: '230px'}}>
        {props.header}
      </div>
      <div className={`flex flex-1 flex-col gap-4 ${contentFrame}`} style={{minWidth: '230px'}}>
        {props.children}
      </div>
    </div>
  } else {
    return <div className={`flex flex-col gap-4  ${contentFrame} ${cardFrame} overflow-hidden dark:bg-surface-dark-low ${props.className || ''}`}>
      {props.children}
    </div>;
  }
}

interface ActionBarProps {
  voted?: 'up' | 'down' | null | undefined;
  bookmarked?: boolean;
  upvotes: number;
  downvotes: number;
  articleId: string;
  onAction: (action: ArticleAction) => void;
}

function ActionBar(props: ActionBarProps) {
  return <div className='flex flex-row gap-2'>
     <div className='flex items-center content-center justify-center rounded-half border border-background'>
      
      <div
        className='flex flex-row h-8 gap-1 px-2 items-center content-center justify-center cursor-pointer'
        onClick={() => props.onAction({action: 'up-vote', articleId: props.articleId})}
      >
        <Icon size="custom" src={props.voted === 'up' ? upvoteFillIcon : upvoteIcon}/>
        <BodyDark className={'text-admin-muted text-lg dark:text-white'}>{props.upvotes}</BodyDark>
      </div>

      <div
        onClick={() => props.onAction({action: 'down-vote', articleId: props.articleId})}
        className='flex flex-row h-8 gap-1 px-2 items-center content-center justify-center cursor-pointer' style={{borderLeft: `1px solid #F7F7F7`}}
      >
        <Icon size="custom" src={props.voted === 'down' ? downvoteFillIcon : downvoteIcon} />
        <BodyDark className={'text-admin-muted text-lg dark:text-white'}>{props.downvotes}</BodyDark>
      </div>
      
     </div>

     <div className='flex-grow'></div>

     <div
      onClick={() => props.onAction({action: 'bookmark', articleId: props.articleId})}
      className='w-8 h-8 flex items-center content-center justify-center rounded-full border border-background cursor-pointer'
    >
      <Icon size="custom" src={props.bookmarked ? bookmarkFillIcon : bookmarkIcon} />
     </div>
      
     <div
      onClick={() => props.onAction({action: 'share', articleId: props.articleId})}
      className='w-8 h-8 flex items-center content-center justify-center rounded-full border border-background cursor-pointer'
    >
      <Icon size="custom" src={shareIcon} />
     </div>

  </div>
}

interface ArticleDescriptionProps {
  size?: 'lg' | 'md' | 'sm';
  headline: string;
  image?: string;
  blurhash?: string;
  onClick?: () => void;
  children?: any;
}

export function ArticleHeadline(props: ArticleDescriptionProps) {
  let fontSize = '';
  if(props.size === 'lg') {
    fontSize = 'text-[32px]';
  } else if(props.size === 'md') {
    fontSize = 'text-[28px]';
  } else if(props.size === 'sm' || ! props.size) {
    fontSize = 'text-[20px]';
  }
  return <div className='flex flex-row gap-3 flex-grow cursor-pointer' onClick={props.onClick}>
    <div className='flex-grow'>
      <ArticleTitle className={`line-clamp-3 dark:text-white ${fontSize}`}>
       {props.headline}
      </ArticleTitle>
      {props.children}
    </div>
    {props.image ? <>
      <ImageTest
        blurhash={props.blurhash}
        height={80}
        className={`bg-gray-20 w-[80px] h-[80px] min-w-[80px] min-h-[80px] max-w-[80px] max-h-[80px]`}
        src={props.image}
        mini
      />
    </> : null}
  </div>;
}

interface ImageTestProps {
  src: string;
  height: number;
  blurhash?: string;
  style?: any;
  className?: string;
  clickEvent?: any;
  mini?: boolean;
}
export function ImageTest(props: ImageTestProps) {

  return <div className={`relative flex content-center justify-center ${props.className || ''}`} style={{height: props.height + 'px', 'overflow': 'hidden'}}>
    <div className='absolute' style={{width: '100%', height: '100%'}}>
      {props.blurhash ? 
        <Blurhash hash={props.blurhash || ''} height={props.height}/>
        :
        <div className={`opacity-75 absolute h-full w-full bg-cover bg-center blur-lg`} style={{backgroundImage: `url(${props.src})`}}></div>
      }
    </div>
    <img src={props.src} className={`${props.mini ? 'min-w[230px]' : ''}`} style={{height: props.height + 'px', opacity: 0, position: 'relative', transition: 'opacity 2s', boxShadow: '0px 0px 12px 17px #0000004d', objectFit: 'contain'}} onLoad={(e) => e.target['style'].opacity = 1}></img>
  </div>
}