"use strict";
/* eslint-disable import/no-cycle */
// @copyright 2022 Navigo, Inc
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.putArticle = exports.removeArticleTags = exports.assignArticleTags = exports.notifyArticle = exports.getFeed = exports.bookmarkArticle = exports.downVoteArticle = exports.handleBookmark = exports.handleVote = exports.upVoteArticle = exports.listArticlesNearMe = exports.listArticlesForMeV2 = exports.listArticlesForMe = exports.listArticlesByScopesV2 = exports.listArticlesByTagV2 = exports.listArticlesByFeed = exports.listArticlesByCurator = exports.listArticlesByTopic = exports.listArticlesByTags = exports.listArticlesByPublication = exports.listArticlesByCategory = exports.listHomeArticles = exports.listArticles = exports.deleteArticle = exports.createArticle = exports.updateArticle = exports.readArticleV2 = exports.readArticle = exports.getTrendingView = void 0;
var data_1 = require("../data");
var http_1 = require("./http");
var json_fetch_1 = require("./json-fetch");
var config_1 = require("../config");
var SERVICE_NAME = 'articles';
var JSON_PATH = '_';
function url(isAdmin) {
    if (isAdmin === void 0) { isAdmin = false; }
    return isAdmin ? "".concat((0, config_1.getConfig)().mxmApiAdminUrl, "/articles") : "".concat((0, config_1.getConfig)().mxmApiWebUrl, "/articles");
}
function resolveArticle() {
    return (0, http_1.createEntity)(JSON_PATH, data_1.Article);
}
function getTrendingView() {
    return __awaiter(this, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, json_fetch_1.ApiFetch)({
                        url: "".concat((0, config_1.getConfig)().mxmApiWebUrl, "/view-articles")
                    })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, result.data];
            }
        });
    });
}
exports.getTrendingView = getTrendingView;
function readArticle(id, params) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, 'readArticle', (0, http_1.createHTTPRequest)('GET', "".concat(url(params === null || params === void 0 ? void 0 : params.isAdmin), "/").concat(id), params), resolveArticle());
}
exports.readArticle = readArticle;
function readArticleV2(id, params) {
    return __awaiter(this, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, json_fetch_1.ApiFetch)({
                        url: "".concat(url(params === null || params === void 0 ? void 0 : params.isAdmin), "/").concat(id)
                    })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, result === null || result === void 0 ? void 0 : result.data];
            }
        });
    });
}
exports.readArticleV2 = readArticleV2;
function updateArticle(id, params) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, 'updateArticle', (0, http_1.createHTTPRequest)('PATCH', "".concat(url(true), "/").concat(id), __assign({}, params), undefined, {
                    'Content-Type': 'application/json',
                }), resolveArticle())];
        });
    });
}
exports.updateArticle = updateArticle;
function createArticle(id, params) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var result, ids;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, 'createArticle', (0, http_1.createHTTPRequest)('POST', "".concat(url(true)), __assign({}, params), undefined, {
                        'Content-Type': 'application/json',
                    }), resolveArticle())];
                case 1:
                    result = _b.sent();
                    if (!(result.id && ((_a = params === null || params === void 0 ? void 0 : params.tags) === null || _a === void 0 ? void 0 : _a.length))) return [3 /*break*/, 3];
                    ids = params.tags.map(function (t) { return t.id; });
                    return [4 /*yield*/, assignArticleTags(result.id, ids)];
                case 2:
                    _b.sent();
                    return [2 /*return*/, readArticle(result.id)];
                case 3: return [2 /*return*/, result];
            }
        });
    });
}
exports.createArticle = createArticle;
function deleteArticle(id, params) {
    return (0, http_1.resolveTextHTTPResponse)(SERVICE_NAME, 'deleteArticle', (0, http_1.createHTTPRequest)('DELETE', "".concat(url(params === null || params === void 0 ? void 0 : params.isAdmin), "/").concat(id), params), function () { return ({ id: id }); });
}
exports.deleteArticle = deleteArticle;
function listArticles(page, count, params) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, 'listArticles', (0, http_1.createHTTPRequest)('GET', "".concat(url(params === null || params === void 0 ? void 0 : params.isAdmin)), params), (0, http_1.createPaginatedCollection)(JSON_PATH, data_1.Articles, data_1.Article));
}
exports.listArticles = listArticles;
function listHomeArticles(params, scope) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, 'listHomeArticles', (0, http_1.createHTTPRequest)('GET', "".concat((0, config_1.getConfig)().mxmApiWebUrl, "/home") + (scope ? "?scope=".concat(scope) : ''), params), (0, http_1.createPaginatedCollection)(JSON_PATH, data_1.Articles, data_1.Article));
}
exports.listHomeArticles = listHomeArticles;
function listArticlesByCategory(category, page, count, params) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, "listArticlesByCategory".concat(category), (0, http_1.createHTTPRequest)('GET', "".concat(url(params === null || params === void 0 ? void 0 : params.isAdmin), "?scope=category:").concat(category, "&page=").concat(page, "&count=").concat(count), params), (0, http_1.createPaginatedCollection)(JSON_PATH, data_1.Articles, data_1.Article));
}
exports.listArticlesByCategory = listArticlesByCategory;
function listArticlesByPublication(publication, page, count, params) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, "listArticlesByPublication".concat(publication), (0, http_1.createHTTPRequest)('GET', "".concat(url(params === null || params === void 0 ? void 0 : params.isAdmin), "?scope=publication:").concat(publication, "&page=").concat(page, "&count=").concat(count), params), (0, http_1.createPaginatedCollection)(JSON_PATH, data_1.Articles, data_1.Article));
}
exports.listArticlesByPublication = listArticlesByPublication;
function listArticlesByTags(tags, page, count, params) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, "listArticlesByTags".concat(tags), (0, http_1.createHTTPRequest)('GET', "".concat(url(params === null || params === void 0 ? void 0 : params.isAdmin), "?scope=").concat(tags, "&page=").concat(page, "&count=").concat(count), params), (0, http_1.createPaginatedCollection)(JSON_PATH, data_1.Articles, data_1.Article));
}
exports.listArticlesByTags = listArticlesByTags;
function listArticlesByTopic(topic, page, count, params) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, "listArticlesByTopic".concat(topic), (0, http_1.createHTTPRequest)('GET', "".concat(url(params === null || params === void 0 ? void 0 : params.isAdmin), "?scope=topic:").concat(topic, "&page=").concat(page, "&count=").concat(count), params), (0, http_1.createPaginatedCollection)(JSON_PATH, data_1.Articles, data_1.Article));
}
exports.listArticlesByTopic = listArticlesByTopic;
function listArticlesByCurator(curator, page, count, params) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, "listArticlesByCurator".concat(curator), (0, http_1.createHTTPRequest)('GET', "".concat(url(params === null || params === void 0 ? void 0 : params.isAdmin), "?scope=curator:").concat(curator, "&page=").concat(page, "&count=").concat(count), params), (0, http_1.createPaginatedCollection)(JSON_PATH, data_1.Articles, data_1.Article));
}
exports.listArticlesByCurator = listArticlesByCurator;
function listArticlesByFeed(feed, page, count, params) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, "listArticlesByFeed", (0, http_1.createHTTPRequest)('GET', "".concat(url(params === null || params === void 0 ? void 0 : params.isAdmin), "?scope=feed:").concat(feed, "&page=").concat(page, "&count=").concat(count), params), (0, http_1.createPaginatedCollection)(JSON_PATH, data_1.Articles, data_1.Article));
}
exports.listArticlesByFeed = listArticlesByFeed;
function listArticlesByTagV2(tagIds, page, count, params) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, "listArticlesByFeedV2", (0, http_1.createHTTPRequest)('GET', "".concat(url(params === null || params === void 0 ? void 0 : params.isAdmin), "?tags=").concat(tagIds.join(','), "&page=").concat(page, "&count=").concat(count), params), (0, http_1.createPaginatedCollection)(JSON_PATH, data_1.Articles, data_1.Article));
}
exports.listArticlesByTagV2 = listArticlesByTagV2;
function listArticlesByScopesV2(scopes, page, count, params) {
    return __awaiter(this, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, json_fetch_1.ApiFetch)({
                        method: 'GET',
                        url: "".concat(url(params === null || params === void 0 ? void 0 : params.isAdmin), "?scope=").concat(scopes.join(','), "&page=").concat(page, "&count=").concat(count)
                    })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, (result === null || result === void 0 ? void 0 : result.data) || []];
            }
        });
    });
}
exports.listArticlesByScopesV2 = listArticlesByScopesV2;
function listArticlesForMe(page, count, filters, params) {
    var expandedFilters = filters === null || filters === void 0 ? void 0 : filters.map(function (filter) { return "scope=category:".concat(filter); }).join('&');
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, 'listArticlesForMe', (0, http_1.createHTTPRequest)('GET', "".concat(url(params === null || params === void 0 ? void 0 : params.isAdmin), "?scope=feed:for-me&").concat(expandedFilters !== '' ? "".concat(expandedFilters, "&") : '', "page=").concat(page, "&count=").concat(count), params), (0, http_1.createPaginatedCollection)(JSON_PATH, data_1.Articles, data_1.Article));
}
exports.listArticlesForMe = listArticlesForMe;
function listArticlesForMeV2(filters, page, count, params) {
    return __awaiter(this, void 0, void 0, function () {
        var scope, getUrl, result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    scope = "";
                    getUrl = "".concat(url(params === null || params === void 0 ? void 0 : params.isAdmin), "?scope=feed:for-me&page=").concat(page, "&count=").concat(count);
                    if (filters.length) {
                        getUrl = "".concat(url(params === null || params === void 0 ? void 0 : params.isAdmin), "?scope=").concat(filters.join(','), "&page=").concat(page, "&count=").concat(count);
                    }
                    return [4 /*yield*/, (0, json_fetch_1.ApiFetch)({
                            method: 'GET',
                            url: getUrl
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, (result === null || result === void 0 ? void 0 : result.data) || []];
            }
        });
    });
}
exports.listArticlesForMeV2 = listArticlesForMeV2;
function listArticlesNearMe(filters, page, count, params) {
    return __awaiter(this, void 0, void 0, function () {
        var scope, getUrl, result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    scope = "";
                    getUrl = "".concat(url(params === null || params === void 0 ? void 0 : params.isAdmin), "?scope=feed:near-me&page=").concat(page, "&count=").concat(count);
                    if (filters.length) {
                        getUrl = "".concat(url(params === null || params === void 0 ? void 0 : params.isAdmin), "?scope=").concat(filters.join(','), "&page=").concat(page, "&count=").concat(count);
                    }
                    return [4 /*yield*/, (0, json_fetch_1.ApiFetch)({
                            method: 'GET',
                            url: getUrl
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, (result === null || result === void 0 ? void 0 : result.data) || []];
            }
        });
    });
}
exports.listArticlesNearMe = listArticlesNearMe;
function upVoteArticle(id, params) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, 'upVoteArticle', (0, http_1.createHTTPRequest)('GET', "".concat(url(params === null || params === void 0 ? void 0 : params.isAdmin), "/").concat(id, "/upvote"), params), resolveArticle());
}
exports.upVoteArticle = upVoteArticle;
function handleVote(id, vote) {
    return __awaiter(this, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (vote === 'up-vote' ? upVoteArticle(id) : downVoteArticle(id))];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, result.toParams()];
            }
        });
    });
}
exports.handleVote = handleVote;
function handleBookmark(article) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!article.bookmarked) return [3 /*break*/, 2];
                    return [4 /*yield*/, bookmarkArticleV2(article.id, true)];
                case 1:
                    _a.sent();
                    return [2 /*return*/, __assign(__assign({}, article), { bookmarked: false })];
                case 2: return [4 /*yield*/, bookmarkArticleV2(article.id, false)];
                case 3:
                    _a.sent();
                    return [2 /*return*/, __assign(__assign({}, article), { bookmarked: true })];
            }
        });
    });
}
exports.handleBookmark = handleBookmark;
function bookmarkArticleV2(id, remove) {
    return __awaiter(this, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, json_fetch_1.ApiFetch)({
                        method: remove ? 'DELETE' : 'GET',
                        url: "".concat(url(), "/").concat(id, "/bookmark")
                    })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, result === null || result === void 0 ? void 0 : result.data];
            }
        });
    });
}
function downVoteArticle(id, params) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, 'downVoteArticle', (0, http_1.createHTTPRequest)('GET', "".concat(url(params === null || params === void 0 ? void 0 : params.isAdmin), "/").concat(id, "/downvote"), params), resolveArticle());
}
exports.downVoteArticle = downVoteArticle;
function bookmarkArticle(cacheState, id, params) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, 'bookmarkArticle', (0, http_1.createHTTPRequest)('GET', "".concat(url(params === null || params === void 0 ? void 0 : params.isAdmin), "/").concat(id, "/bookmark"), params), resolveArticle());
}
exports.bookmarkArticle = bookmarkArticle;
function getFeed(params) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, 'getFeed', (0, http_1.createHTTPRequest)('GET', "".concat((0, config_1.getConfig)().mxmApiWebUrl, "/trending-articles"), params), function (json) {
        var feed = json.data._;
        var data = Object.keys(feed).reduce(function (acc, key) { return __spreadArray(__spreadArray([], __read(acc), false), [new data_1.TrendingArticle({ section: key, articles: feed[key] })], false); }, []);
        return new data_1.TrendingArticles(data, data_1.TrendingArticle);
    });
}
exports.getFeed = getFeed;
function notifyArticle(params) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, json_fetch_1.ApiFetch)({
                        method: 'POST',
                        url: "".concat((0, config_1.getConfig)().mxmApiAdminUrl, "/notify"),
                        data: params,
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.notifyArticle = notifyArticle;
function assignArticleTags(articleId, tags) {
    return (0, json_fetch_1.JsonFetch)({ method: 'POST', url: "".concat(url(true), "/").concat(articleId, "/tags"), data: tags });
}
exports.assignArticleTags = assignArticleTags;
function removeArticleTags(articleId, tagId) {
    return (0, json_fetch_1.JsonFetch)({ method: 'DELETE', url: "".concat(url(true), "/").concat(articleId, "/tags/").concat(tagId), data: {} });
}
exports.removeArticleTags = removeArticleTags;
/**
 * Logic for creating/updating articles and their tags.
 *
 * TODO: It would be better if the API handles this sort of logic.
 */
function putArticle(article) {
    return __awaiter(this, void 0, void 0, function () {
        var newArticle, result_1, articleId, result, currentTags, nextTags, addTags, deleteTags, deleteTags_1, deleteTags_1_1, tag, e_1_1;
        var e_1, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!!article.id) return [3 /*break*/, 2];
                    return [4 /*yield*/, createArticle('', article)];
                case 1: return [2 /*return*/, _b.sent()];
                case 2: return [4 /*yield*/, updateArticle(article.id, article)];
                case 3:
                    result_1 = _b.sent();
                    newArticle = result_1.toParams();
                    _b.label = 4;
                case 4:
                    articleId = (newArticle === null || newArticle === void 0 ? void 0 : newArticle.id) || article.id;
                    if (!articleId) {
                        return [2 /*return*/, article];
                    }
                    if (!(article.tags instanceof Array)) {
                        return [2 /*return*/, article];
                    }
                    return [4 /*yield*/, readArticle(articleId)];
                case 5:
                    result = _b.sent();
                    currentTags = (result.toParams().tags || []).map(function (t) { return t.id; }).filter(function (t) { return t; });
                    nextTags = (article.tags || []).map(function (t) { return t.id; }).filter(function (t) { return t; });
                    addTags = nextTags.filter(function (nextTag) { return currentTags.includes(nextTag) === false; });
                    deleteTags = currentTags.filter(function (currentTag) { return nextTags.includes(currentTag) === false; });
                    if (!addTags.length) return [3 /*break*/, 7];
                    return [4 /*yield*/, assignArticleTags(articleId, addTags)];
                case 6:
                    _b.sent();
                    _b.label = 7;
                case 7:
                    if (!deleteTags.length) return [3 /*break*/, 15];
                    _b.label = 8;
                case 8:
                    _b.trys.push([8, 13, 14, 15]);
                    deleteTags_1 = __values(deleteTags), deleteTags_1_1 = deleteTags_1.next();
                    _b.label = 9;
                case 9:
                    if (!!deleteTags_1_1.done) return [3 /*break*/, 12];
                    tag = deleteTags_1_1.value;
                    return [4 /*yield*/, removeArticleTags(articleId, tag)];
                case 10:
                    _b.sent();
                    _b.label = 11;
                case 11:
                    deleteTags_1_1 = deleteTags_1.next();
                    return [3 /*break*/, 9];
                case 12: return [3 /*break*/, 15];
                case 13:
                    e_1_1 = _b.sent();
                    e_1 = { error: e_1_1 };
                    return [3 /*break*/, 15];
                case 14:
                    try {
                        if (deleteTags_1_1 && !deleteTags_1_1.done && (_a = deleteTags_1.return)) _a.call(deleteTags_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                    return [7 /*endfinally*/];
                case 15: return [2 /*return*/, article];
            }
        });
    });
}
exports.putArticle = putArticle;
