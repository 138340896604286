import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router';
import { Modal, Notifications } from '@roc-digital/ui-web';
import { closeModal, useCustomEvent, } from '@roc-digital/ui-lib';
import React, { useEffect, useState } from 'react';
import { Data, Event } from '@roc-digital/mxm-base/types';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppContainer from './elements/AppContainer';
import Trending from '@/components/views/Trending';
import ForYou from '@/components/views/ForYou';
import PrivacyPolicy from './views/PrivacyPolicy';
import Terms from './views/Terms';
import Careers from './views/Careers';
import About from './views/About';
import { useAuthState } from '@/logic';
import Home from './views/Home';
import Account from './views/Account';
import Profile from './views/Account/Profile';
import SavedArticles from './views/Account/SavedArticles';
import AccountNotifications from './views/Account/Notifications';
import Interests from './views/Account/Interests';
import Locations from './views/Account/Locations';
import Following from './views/Account/Following';
import Billing from './views/Account/Billing';
import AccountTheme from './views/Account/AccountTheme';
import Article from '@/components/views/Article';
import { ForgotPassword } from './views/Login/ForgotPassword';
import Help from './views/Help';
import Header from './elements/Header';
import { useKeepTop } from '@/logic/useKeepTop';
import { SignIn } from '@/components/views/Login';
import Local from './views/Local';

export const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = React.useState<string>('');
  const [isLoggedOut, setIsLoggedOut] = React.useState<boolean>(false);
  const [isAnon, setIsAnon] = React.useState<boolean>(true);
  const [search, setSearch] = useState<string>('');
  const auth = useAuthState();

  const { pathname } = useLocation();
  const isLoginSignup = (pathname === '/login' || pathname === '/signup' || pathname === '/forgotpassword' ? true : false);

  useKeepTop();

  useEffect(() => {
    if (localStorage.theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, []);

  return (
    <>
      <div className="min-h-screen h-full flex flex-col dark:bg-surface-dark-low">
        {!isLoginSignup ? (
          <div className="relative z-1050 pt-2 dark:bg-surface-dark-high">
          <Header isLoggedOut={auth.authenticated !== true} />
        </div>
        ) : null}
        <AppContainer>
          <Routes>
            <Route path="/" element={<Home isAuthenticated={auth.authenticated} />} />
            <Route path="/home" element={<Home isAuthenticated={auth.authenticated} />} />
            <Route
              path="/trending"
              element={
                <Trending
                  isAuthenticated={auth.authenticated}
                  isAnon={isAnon}
                  isLoggedOut={isLoggedOut}
                  search={search}
                />
              }
            />
            <Route
              path="/trending/:tag"
              element={
                <Trending
                  isAuthenticated={auth.authenticated}
                  isAnon={isAnon}
                  isLoggedOut={isLoggedOut}
                  search={search}
                />
              }
            />
            <Route 
              path="/article/:id" 
              element={
                <Article 
                  isAuthenticated={auth.authenticated}
                  isAnon={isAnon}
                  isLoggedOut={isLoggedOut}
                />
              } 
            />
            <Route path="/login" element={<SignIn loginMode='login' />} />
            <Route path="/signup" element={<SignIn loginMode='sign-up'/>} />
            <Route path="/forgotpassword" Component={ForgotPassword}/>
            <Route path="/privacy-policy" Component={PrivacyPolicy} />
            <Route path="/terms" Component={Terms} />
            <Route path="/careers" Component={Careers} />
            <Route path="/about" Component={About} />
            <Route path="/help" Component={Help} />
            {auth.authenticated ? <>
              <Route path="/foryou" Component={ForYou} />
              <Route path="/local" Component={Local} />
              <Route path="/account" Component={Account}>
                <Route path="profile" Component={Profile} />
                <Route path="notifications" Component={AccountNotifications} />
                <Route path="saved" Component={SavedArticles} />
                <Route path="interests" Component={Interests} />
                <Route path="locations" Component={Locations} />
                <Route path="following" Component={Following} />
                <Route path="theme" Component={AccountTheme} />
                <Route path="billing" Component={Billing} />
              </Route>
            </> : <>
              <Route path={'/foryou'} element={<SignIn loginMode='login' />} />
              <Route path={'/local'} element={<SignIn loginMode='login' />} />
              <Route path="/account/*" element={<Navigate to="/login" />}/>
            </>}
          </Routes>
        </AppContainer>
        <Notifications />
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
      <Modal id={'mxm.modal'} />
    </>
  );
};
