import { Text, TextProps } from './Text';

export const Heading1 = ({ children, className, ...props }: TextProps) => (
  <Text {...props} className={`font-[Chivo-ExtraBold] text-3xl tracking-tighter leading-tight ${className}`}>
    {children}
  </Text>
);

export const Heading1Italic = ({ children, className, ...props }: TextProps) => (
  <Text {...props} className={`font-[Chivo-ExtraBoldItalic] text-3xl tracking-tighter leading-tight ${className}`}>
    {children}
  </Text>
);

export const Heading2 = ({ children, className, ...props }: TextProps) => (
  <Text {...props} className={`font-[Chivo-Bold] text-xl ${className}`}>
    {children}
  </Text>
);

export const Heading2Italic = ({ children, className, ...props }: TextProps) => (
  <Text {...props} className={`font-[Chivo-BoldItalic] text-xl ${className}`}>
    {children}
  </Text>
);

export const Heading3 = ({ children, className, ...props }: TextProps) => (
  <Text {...props} className={`font-[Chivo-Regular] text-lg ${className}`}>
    {children}
  </Text>
);

export const Heading3Italic = ({ children, className, ...props }: TextProps) => (
  <Text {...props} className={`font-[Chivo-Italic] text-xl ${className}`}>
    {children}
  </Text>
);

export const BodyDark = ({ children, className, ...props }: TextProps) => (
  <Text {...props} className={`!font-workSans text-sm text-admin-dark ${className} dark:text-white`}>
    {children}
  </Text>
);

export const BodyDarkItalic = ({ children, className, ...props }: TextProps) => (
  <Text {...props} className={`!font-workSans !italic text-sm text-admin-dark ${className}`}>
    {children}
  </Text>
);

export const BodyLight = ({ children, className, ...props }: TextProps) => (
  <Text {...props} className={`font-workSans text-admin-light ${className}`}>
    {children}
  </Text>
);

export const BodyMuted = ({ children, className, ...props }: TextProps) => (
  <Text {...props} className={`font-workSans text-admin-muted ${className}`}>
    {children}
  </Text>
);

export const BodyItalic = ({ children, className, ...props }: TextProps) => (
  <Text {...props} className={`font-workSans !italic text-sm ${className}`}>
    {children}
  </Text>
);

export const BodySemibold = ({ children, className, ...props }: TextProps) => (
  <Text {...props} className={`font-workSans !font-bold text-lg ${className}`}>
    {children}
  </Text>
);

export const BodySemiboldItalic = ({ children, className, ...props }: TextProps) => (
  <Text {...props} className={`font-workSans !font-bold !italic text-lg ${className}`}>
    {children}
  </Text>
);

export const BodyRegular = ({ children, className, ...props }: TextProps) => (
  <Text {...props} className={`font-workSans text-lg ${className}`}>
    {children}
  </Text>
);

export const ArticleTitle = ({ children, className, ...props }: TextProps) => (
  <Text {...props} className={`font-pragati !font-bold ${className} dark:text-white sm:text-2xl`}>
    {children}
  </Text>
);
