import { useEffect, useRef } from "react";

const HomeTitle = () => {
  const divRef2 = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    if (divRef2.current) {
        divRef2.current.classList.add('easeLoadedImage');
    }
  }, []);
  
  return (
    <div ref={divRef2} className="easeloadSlide text-9xl font-bold max-w-md text-center text-font-high home-title">
        News
        <br />
        Without
        <br />
        Bias
    </div>
  );
};

export default HomeTitle;
