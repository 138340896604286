import React, { useEffect } from "react"

export interface UseMethodOptions<T extends ((...args: any[]) => Promise<any>)> {
  onSuccess?: (result: Awaited<ReturnType<T>>, args: Parameters<T>) => void;
  onBefore?: (args: Parameters<T>) => Promise<Parameters<T> | undefined>;
  onError?: (error: any, args: Parameters<T>) => void;
  defaultValue?: Awaited<ReturnType<T>>;
  autoRun?: Parameters<T>;
}

/**
 * Converts an asynchronous method into a React hook. Breaks out the following states that are often needed
 * when calling asynchronous methods from within a React component.
 * 
 * - result: The last value returned from the method
 * - loading: The boolean loading state of the method
 * - count: The amount of times the method has been called
 * - debounce: Execute the method, but debounce it per the period given to useMethod.
 * - run: Immediately execute the method without debouncing it
 * - error: The error, if any, from the last method execution.
 * 
 */
export function useMethod<T extends ((...args: any[]) => Promise<any>)>(method: T, options?: UseMethodOptions<T>) {
  const [result, setResult] = React.useState<Awaited<ReturnType<T>> | undefined>(options?.defaultValue);
  const [error, setError] = React.useState<any>();
  const [loading, setLoading] = React.useState(false);

  const state = React.useRef({
    timer: 0 as any,
    loading: false,
    promise: null as any as Promise<Awaited<ReturnType<T>>>,
    res: null as any as (value: any) => void,
    rej: null as any as (value: any) => void,
    count: 0,
    options,
    lastResult: null as any as Awaited<ReturnType<T>>,
    lastArgs: undefined as any as Parameters<T> | undefined
  })

  state.current.options = options;

  const reset = () => {
    if(state.current.timer) {
      clearTimeout(state.current.timer);
    }

    if(!state.current.promise) {
      state.current.promise = new Promise((res, rej) => {
        state.current.res = res;
        state.current.rej = rej;
      });
      setLoading(true);
    }
  };
  
  const exec = async (...args: Parameters<T>) => {
    const currentCount = state.current.count + 1;
    state.current.count = currentCount;
    setError(null);
    state.current.lastArgs = args;
    method(...args)
    .then((v) => {
      if(currentCount !== state.current.count) {
        // Method was called mid-request. Ignore it.
        // It would be nice to cancel the request.
        return;
      }

      state.current.lastResult = v;
      state.current.promise = null as any;

      if(state.current.options?.onSuccess) {
        state.current.options?.onSuccess(v, args);
      }

      setLoading(false);
      setResult(v);
      state.current.res(v);
    })
    .catch((e) => {
      if(currentCount !== state.current.count) {
        // Method was called mid-request. Ignore it.
        // It would be nice to cancel the request.
        return;
      }

      if(state.current.options?.onError) {
        state.current.options?.onError(e, args);
      }

      state.current.promise = null as any;
      setLoading(false);
      setError(e);
      state.current.rej(e);
    })
  }


  const debounce = React.useCallback(async (args: Parameters<T>, period = 500) => {
    const onBefore = state.current.options?.onBefore || (async (args) => args);

    let fromBeforeArgs = await onBefore(args);

    if(args instanceof Array) {
      args = fromBeforeArgs as any;
    } else {
      return;
    }

    reset();
    state.current.timer = setTimeout(() => {
      exec(...args)
    }, period);

    return state.current.promise;
  }, [method]);

  const run = async (...args: Parameters<T>) => {
    const onBefore = state.current.options?.onBefore || (async (args) => args);

    let fromBeforeArgs = await onBefore(args);

    if(args instanceof Array) {
      args = fromBeforeArgs as any;
    } else {
      return;
    }

    if(!args) {
      return;
    }

    reset();
    exec(...args);
    return state.current.promise;
  };

  const reload = async () => {
    let args = state.current.lastArgs;
    if(!args) return;

    const onBefore = state.current.options?.onBefore || (async (args) => args);

    let fromBeforeArgs = await onBefore(args);

    if(args instanceof Array) {
      args = fromBeforeArgs as any;
    } else {
      return;
    }

    if(!args) {
      return;
    }

    reset();
    exec(...args);
    return state.current.promise;
  };

  const getLastResult = React.useCallback(() => state.current.lastResult || options?.defaultValue as Awaited<ReturnType<T>>, [options?.defaultValue]);

  useEffect(() => {
    if(!options?.autoRun || !(options?.autoRun instanceof Array)) {
      return;
    }

    debounce(options?.autoRun, 60).catch(() => {});
  }, options?.autoRun || []);

  return {
    debounce,
    run,
    reload,
    loading,
    error,
    result: result || options?.defaultValue,
    count: state.current.count,
    setResult: (value: Awaited<ReturnType<T>>) => {
      state.current.lastResult = value;
      setResult(value);
    },
    getLastResult
  };
}
