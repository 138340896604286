import { getToken } from "@roc-digital/mxm-base/logic";
// const baseURL = 'http://localhost:8082';
const baseURL = 'https://admin-unfurl-api.qa-mxmnews.com';

export const createSubscription = async (data: any) => {
  const response = await fetch(`${baseURL}/create-subscription`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + await getToken()
    },
    body: JSON.stringify(data),
  });
  
  return response.json();
};

export const listCharges = async (data: any) => {
  const response = await fetch(`${baseURL}/list-charges`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + await getToken()
    },
    body: JSON.stringify(data),
  });
  
  return response.json();
};

export const updatePaymentMethod = async (data: any) => {
  const response = await fetch(`${baseURL}/update-payment-method`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + await getToken()
    },
    body: JSON.stringify(data),
  });
  
  return response.json();
};

export const cancelSubscription = async (data: any) => {
  const response = await fetch(`${baseURL}/cancel-subscription`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + await getToken()
    },
    body: JSON.stringify(data),
  });
  
  return response.json();
};