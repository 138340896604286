"use strict";
/* eslint-disable import/no-cycle */
// @copyright 2023 MXM, Inc
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createEntity = exports.createCollection = exports.createPaginatedCollection = exports.createHTTPRequest = exports.resolveTextHTTPResponse = exports.resolveJsonHTTPResponse = exports.resolveHTTPResponse = exports.sendHttpRequest = exports.handleHttpEvents = exports.generateRequestHash = exports.initHttp = exports.HttpError = exports.getToken = exports.setGetTokenHook = void 0;
var data_1 = require("../data");
var logging_1 = require("./logging");
var validation_1 = require("./validation");
var events_1 = require("../events");
var utils_1 = require("./utils");
var config_1 = require("../config");
var getTokenHook = function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    return [2 /*return*/, ''];
}); }); };
function setGetTokenHook(hooK) {
    getTokenHook = hooK;
}
exports.setGetTokenHook = setGetTokenHook;
function getToken() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getTokenHook()];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
exports.getToken = getToken;
var HttpError = /** @class */ (function (_super) {
    __extends(HttpError, _super);
    function HttpError(message) {
        var _this = _super.call(this, message) || this;
        _this.setResponse = function (response, json) {
            _this.response = response;
            _this.json = json;
        };
        _this.getResponse = function () { return _this.response; };
        _this.getJson = function () { return _this.json; };
        return _this;
    }
    HttpError.prototype.setResponse = function (response, json) {
        this.response = response;
        this.json = json;
    };
    HttpError.prototype.getResponse = function () {
        return this.response;
    };
    HttpError.prototype.getJson = function () {
        return this.json;
    };
    HttpError.prototype.hasErrors = function () {
        var _a, _b;
        return typeof ((_a = this.json) === null || _a === void 0 ? void 0 : _a.errors) !== 'undefined' && ((_b = this.json) === null || _b === void 0 ? void 0 : _b.errors.length);
    };
    HttpError.prototype.getErrors = function () {
        var _a;
        return typeof ((_a = this.json) === null || _a === void 0 ? void 0 : _a.errors) !== 'undefined' ? this.json.errors : [];
    };
    return HttpError;
}(Error));
exports.HttpError = HttpError;
var bootstrap;
function initHttp(cacheState, sessionState, mocksState) {
    (0, logging_1.log)('[http.initHttp]', [cacheState, sessionState]);
    bootstrap = {
        version: (0, config_1.getConfig)().appVersion,
        device: (0, config_1.getConfig)().appDevice,
        cache: cacheState,
        session: sessionState,
        mocks: mocksState,
    };
}
exports.initHttp = initHttp;
function generateRequestHash(method, url, queryParams, params, files) {
    return "".concat(method, " ").concat(url, " ").concat(JSON.stringify((0, utils_1.sortByKeys)(queryParams || {})), " ").concat(JSON.stringify((0, utils_1.sortByKeys)(params || {})), " ").concat(JSON.stringify(files === null || files === void 0 ? void 0 : files.map(function (file) { return file.name; })));
}
exports.generateRequestHash = generateRequestHash;
function handleHttpResponse(request, params, files) {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    (0, logging_1.log)('[http.handleHttpResponse]', [request]);
                    return [4 /*yield*/, fetch(request)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    });
}
function handleHttpEvents(moduleName, functionName, promise) {
    return __awaiter(this, void 0, void 0, function () {
        var response, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    (0, logging_1.log)('[http.handleHttpEvents]', [moduleName, functionName, promise]);
                    (0, events_1.publish)("mxm.http.".concat(moduleName), "".concat(functionName, ".loading"));
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, promise];
                case 2:
                    response = _a.sent();
                    (0, events_1.publish)("mxm.http.".concat(moduleName), "".concat(functionName, ".success"), response);
                    (0, events_1.publish)("mxm.http.".concat(moduleName), "".concat(functionName, ".complete"));
                    return [2 /*return*/, response];
                case 3:
                    e_1 = _a.sent();
                    console.error(e_1);
                    (0, events_1.publish)("mxm.http.".concat(moduleName), "".concat(functionName, ".failed"), { e: e_1 });
                    (0, events_1.publish)("mxm.http.".concat(moduleName), "".concat(functionName, ".complete"));
                    throw e_1;
                case 4: return [2 /*return*/];
            }
        });
    });
}
exports.handleHttpEvents = handleHttpEvents;
function sendHttpRequest(method, url, params, files, headers, auth, cacheTTL) {
    if (cacheTTL === void 0) { cacheTTL = 1000; }
    return __awaiter(this, void 0, void 0, function () {
        var httpHeaders, jwtToken, _a, paramsLength, body, request, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    (0, logging_1.log)('[http.sendHttpRequest]', [method, url, params, files, headers, auth]);
                    // console.log('headers', await bootstrap.session.getToken());
                    (0, validation_1.validate)({ method: method }, { method: [[validation_1.Rules.ENUM, ['HEAD', 'GET', 'POST', 'PUT', 'PATCH', 'DELETE']]] });
                    if (params && typeof params === 'object' && params instanceof data_1.Entity) {
                        params = params.toParams();
                    }
                    if (!params) {
                        params = {};
                    }
                    if (files && typeof files === 'object' && files instanceof data_1.Entity) {
                        files = files.toParams();
                    }
                    if (!Object.keys(files || {}).length) {
                        files = null;
                    }
                    httpHeaders = headers && headers instanceof Headers ? headers : new Headers();
                    if (headers && !(headers instanceof Headers)) {
                        Object.keys(headers).forEach(function (header) {
                            httpHeaders.append(header, headers[header]);
                        });
                    }
                    httpHeaders.append('Referer', "MXM ".concat(bootstrap.device, " v").concat(bootstrap.version));
                    if (!(typeof auth === 'undefined' || auth === true)) return [3 /*break*/, 5];
                    return [4 /*yield*/, !bootstrap.session.hasToken()];
                case 1:
                    if (_c.sent()) {
                        throw new ReferenceError('No JWT exists in current session');
                    }
                    return [4 /*yield*/, getToken()];
                case 2:
                    _a = (_c.sent());
                    if (_a) return [3 /*break*/, 4];
                    return [4 /*yield*/, bootstrap.session.getToken()];
                case 3:
                    _a = (_c.sent());
                    _c.label = 4;
                case 4:
                    jwtToken = _a;
                    if (jwtToken) {
                        httpHeaders.append('Authorization', "Bearer ".concat(jwtToken));
                    }
                    _c.label = 5;
                case 5:
                    if (!['HEAD', 'GET', 'DELETE'].includes(method) && !files && !httpHeaders.has('Content-Type')) {
                        httpHeaders.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
                    }
                    paramsLength = 0;
                    body = files ? new FormData() : new URLSearchParams();
                    Object.keys(params).forEach(function (param) {
                        if (params === undefined || params[param] === null || params[param] === undefined) {
                            return;
                        }
                        if (['created', 'modified'].includes(param)) {
                            return;
                        }
                        if (params[param] instanceof Boolean || params[param] === true || params[param] === false) {
                            if (httpHeaders.get('Content-Type') !== 'application/json') {
                                params[param] = params[param] ? 'true' : 'false';
                            }
                        }
                        if (params[param] instanceof Date) {
                            params[param] = params[param].toISOString();
                        }
                        if (!['number', 'string'].includes(typeof params[param])) {
                            return;
                        }
                        body.append(param, params[param]);
                        paramsLength++;
                    });
                    if (files !== null && files !== undefined) {
                        Object.keys(files).forEach(function (file) {
                            if (typeof files[file] !== 'object' || files[file] === null) {
                                throw new ReferenceError("File \"".concat(file, "\" is not an object"));
                            }
                            body.append('files', files[file]);
                        });
                    }
                    if (['HEAD', 'GET'].includes(method)) {
                        request = new Request(url + (paramsLength ? "?".concat(body.toString()) : ''), {
                            method: method,
                            headers: httpHeaders,
                        });
                    }
                    else if (httpHeaders.get('Content-Type') === 'application/json') {
                        request = new Request(url, {
                            method: method,
                            headers: httpHeaders,
                            body: JSON.stringify(params),
                        });
                    }
                    else {
                        request = new Request(url, {
                            method: method,
                            headers: httpHeaders,
                            body: files ? body : body.toString(),
                        });
                    }
                    _b = method === 'GET';
                    if (!_b) return [3 /*break*/, 7];
                    return [4 /*yield*/, bootstrap.cache.has(url + (params && Object.keys(params).length > 0 ? "?".concat(body.toString()) : ''))];
                case 6:
                    _b = (_c.sent());
                    _c.label = 7;
                case 7:
                    if (!_b) return [3 /*break*/, 9];
                    return [4 /*yield*/, bootstrap.cache.get(url + (params && Object.keys(params).length > 0 ? "?".concat(body.toString()) : ''))];
                case 8: return [2 /*return*/, ((_c.sent()) || '')];
                case 9: return [2 /*return*/, handleHttpResponse(request, params, files).then(function (response) {
                        var _a;
                        var headers = response.headers;
                        if (headers.has('Content-Type') && ((_a = headers.get('Content-Type')) === null || _a === void 0 ? void 0 : _a.includes('application/json'))) {
                            if (!response.ok) {
                                return response.json().then(function (json) {
                                    (0, logging_1.log)('[http.sendHttpRequest] Response', [json]);
                                    if (typeof json.errors !== 'undefined') {
                                        for (var i = 0; i < json.errors.length; i++) {
                                            (0, logging_1.logWarn)(json.errors[i].message);
                                        }
                                        var error_1 = new HttpError(json.errors[0].message);
                                        error_1.setResponse(response, json);
                                        throw error_1;
                                    }
                                    (0, logging_1.logError)(JSON.stringify(json));
                                    var error = new HttpError('Api Error');
                                    error.setResponse(response, json);
                                    throw error;
                                });
                            }
                            return response.json().then(function (json) {
                                // log('[http.sendHttpRequest] Response', [json]);
                                if (method === 'GET') {
                                    bootstrap.cache.set(url + (params && Object.keys(params).length > 0 ? "?".concat(body.toString()) : ''), json, cacheTTL);
                                }
                                return json;
                            });
                        }
                        if (!response.ok) {
                            return response.text().then(function (text) {
                                (0, logging_1.logError)(text);
                                var error = new HttpError(text);
                                error.setResponse(response, {});
                                throw error;
                            });
                        }
                        return response.text();
                    })];
            }
        });
    });
}
exports.sendHttpRequest = sendHttpRequest;
function resolveHTTPResponse(eventNamespace, eventName, request, responseCallback, auth, cacheTTL) {
    if (cacheTTL === void 0) { cacheTTL = 1000; }
    return __awaiter(this, void 0, void 0, function () {
        var method, url, params, files, headers;
        return __generator(this, function (_a) {
            method = 'GET';
            url = '';
            if (typeof request === 'string') {
                url = request;
            }
            else {
                method = request.method;
                url = request.url;
                params = request.params;
                files = request.files;
                headers = request.headers;
            }
            return [2 /*return*/, handleHttpEvents(eventNamespace, eventName, sendHttpRequest(method, url, params, files, headers, auth, cacheTTL).then(function (json) {
                    try {
                        if (typeof json === 'string') {
                            return responseCallback(json);
                        }
                        return responseCallback(json);
                    }
                    catch (e) {
                        (0, logging_1.logError)(e.message);
                        throw new Error("Failed to resolve HTTP response ".concat(url));
                    }
                }))];
        });
    });
}
exports.resolveHTTPResponse = resolveHTTPResponse;
function resolveJsonHTTPResponse(eventNamespace, eventName, request, responseCallback, auth, cacheTTL) {
    if (cacheTTL === void 0) { cacheTTL = 1000; }
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, resolveHTTPResponse(eventNamespace, eventName, request, function (json) {
                    if (typeof json === 'string') {
                        return responseCallback(JSON.parse(json));
                    }
                    return responseCallback(json);
                }, auth, cacheTTL)];
        });
    });
}
exports.resolveJsonHTTPResponse = resolveJsonHTTPResponse;
function resolveTextHTTPResponse(eventNamespace, eventName, request, responseCallback, auth, cacheTTL) {
    if (cacheTTL === void 0) { cacheTTL = 1000; }
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, resolveHTTPResponse(eventNamespace, eventName, request, function (text) {
                    if (typeof text === 'object') {
                        throw new Error("Failed to resolve HTTP response ".concat(typeof request === 'string' ? request : request.url));
                    }
                    return responseCallback(text);
                }, auth, cacheTTL)];
        });
    });
}
exports.resolveTextHTTPResponse = resolveTextHTTPResponse;
function createHTTPRequest(method, url, params, files, headers, auth) {
    return { method: method, url: url, params: params, files: files, headers: headers, auth: auth };
}
exports.createHTTPRequest = createHTTPRequest;
function createPaginatedCollection(dataName, CollectionClass, EntityClass) {
    return function (json) {
        var _a, _b, _c, _d, _e, _f;
        var meta = ((_a = json.meta) === null || _a === void 0 ? void 0 : _a[dataName]) || json.meta;
        var pagination = new data_1.Pagination((_b = meta === null || meta === void 0 ? void 0 : meta.paging) === null || _b === void 0 ? void 0 : _b.pages, (_c = meta === null || meta === void 0 ? void 0 : meta.paging) === null || _c === void 0 ? void 0 : _c.page, (_d = meta === null || meta === void 0 ? void 0 : meta.paging) === null || _d === void 0 ? void 0 : _d.total, (_e = meta === null || meta === void 0 ? void 0 : meta.paging) === null || _e === void 0 ? void 0 : _e.limit, (_f = meta === null || meta === void 0 ? void 0 : meta.paging) === null || _f === void 0 ? void 0 : _f.count);
        return new CollectionClass(json.data[dataName], EntityClass, pagination);
    };
}
exports.createPaginatedCollection = createPaginatedCollection;
function createCollection(dataName, CollectionClass, EntityClass) {
    return function (json) { return new CollectionClass(json.data[dataName] || json.data, EntityClass); };
}
exports.createCollection = createCollection;
function createEntity(dataName, EntityClass) {
    return function (json) {
        var data = json.data[dataName] || json.data;
        if (Array.isArray(data)) {
            if (data.length > 0) {
                return new EntityClass(data[0]);
            }
            throw new Error("Invalid response ".concat(data));
        }
        return new EntityClass(data);
    };
}
exports.createEntity = createEntity;
