import { memo } from 'react';
import { format } from 'date-fns';
import { getTrendingView } from '@roc-digital/mxm-base/logic';
import { Heading1 } from '@roc-digital/ui-web';
import { Loading } from '@/components/elements/Loading';
import { useMethod } from '@roc-digital/ui-lib';
import ZonePreview from '@/components/views/Trending/ZonePreview';
import AppFooter from '@/components/elements/AppFooter';
import LoginSignup from '@/components/elements/LoginSignup';
import MobileSnackbar from '@/components/elements/MobileSnackbar';

const Trending = memo(
  ({
    isAuthenticated,
    isLoggedOut,
    search,
  }: {
    isAuthenticated: boolean;
    isLoggedOut: boolean;
    isAnon: boolean;
    search: string;
  }) => {
    const trendingQuery = useMethod(getTrendingView, {autoRun: []});

    const activeView = trendingQuery?.result;

    const showMiddleBar1 = (index: number) => {
      return !!(!isAuthenticated && index && index % 3 === 0);
    };

    const showMiddleBar2 = (index: number) => {
      return !!(!isAuthenticated && index && index % 5 === 0);
    };

    return (
      <>
        <div className={'h-full w-full flex flex-col justify-center dark:bg-surface-dark flex-grow'}>
          <div className={'flex w-full flex-col justify-between items-start max-w-5xl mx-auto px-2 mt-8'}>
            <Heading1 className={'text-[#1E222A] !font-extrabold !text-[32px] !leading-9  !tracking-tight !font-chivo dark:text-white'}>
              {format(new Date(), 'MMMM d')}
            </Heading1>
          </div>
          <div className={'flex justify-center w-full'}>
            <div className="flex-grow bg-white dark:bg-surface-dark overflow-hidden">
              {trendingQuery.loading && <Loading customClassName={'mb-8 ml-auto mr-auto'} />}
              {activeView?.zones
                ?.sort((a, b) => (a.order || 0) - (b.order || 0))
                ?.map((zone, index) => (
                  <div key={zone.id} className="zone-gradient dark:zone-gradient-dark pb-10">
                    {showMiddleBar1(index) && !isLoggedOut && (
                      <LoginSignup />
                    )}
                    {showMiddleBar2(index) && !isLoggedOut && (
                      <LoginSignup size="large" />
                    )}
                    <Heading1 className="w-full flex flex-col mt-6 mb-4 max-w-5xl mx-auto px-2 text-action !font-extrabold !text-[32px] !leading-9 !tracking-tight !font-chivo">{zone.title}</Heading1>
                    <ZonePreview
                      search={search}
                      preview={true}
                      key={index}
                      view={activeView}
                      zone={zone}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
        <AppFooter />
        <MobileSnackbar />
      </>
    );
  }
);

Trending.displayName = 'Trending';

export default Trending;
