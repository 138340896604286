import React, { memo, useEffect, useState, useMemo } from 'react';
import { bookmarkArticle, handleBookmark, handleVote, listArticlesForMeV2, listMyTagsV2 } from '@roc-digital/mxm-base/logic';
import { Badge, Heading1, ZoneLayout, BadgeSizes, Modal } from '@roc-digital/ui-web';
import {
  followingIcon,
  politicsIcon,
  financeMoneyIcon,
  policyIcon,
  internationalIcon,
  americaFirstIcon,
  lifeCultureIcon,
  addFillIcon,
  useMethod,
  mapToArticleContent,
  getArticleActionHandler,
} from '@roc-digital/ui-lib';
import { useNavigate } from 'react-router';
import AppFooter from '@/components/elements/AppFooter';
import { Loading } from '@/components/elements/Loading';
import { toast } from 'react-toastify';
import LocalArticles from '@/components/elements/LocalArticles';
import { ArticleSchema } from '@roc-digital/mxm-base/src/data';
import ShareThis from '@/components/elements/ShareThis';

const Local = memo(() => {
  const navigate = useNavigate();
  const [activeFilters, setActiveFilters] = useState<string[]>([]);
  const articlesQuery = useMethod(listArticlesForMeV2);
  const tagQuery = useMethod(listMyTagsV2);

  useEffect(() => {
    tagQuery.run('category');
  }, []);

  useEffect(() => {
    void articlesQuery.run(activeFilters, 1, 50);
  }, [activeFilters]);

  const managePreferences = React.useCallback(() => {
    navigate('/account/interests');
  }, []);

  const toggleFilter = React.useCallback((id: string) => {
    setActiveFilters(activeFilters => {
      if(id === 'all') {
        return [];
      }

      let next: string[] = [];
      if (activeFilters.includes(id)) {
        next = activeFilters.filter((item) => item !== id);
      } else {
        next = [...activeFilters, id];
      }

      next = next.filter((a, i, l) => l.indexOf(a) == i)
      if(next.length > 1) {
        next = next.filter(a => a !== 'all')
      }

      return next;
    });
  }, [])

  return (
    <>
      <div className="h-full w-full flex flex-col justify-center mx-auto pt-0 dark:bg-surface-dark flex-grow">
      { /** 
        <div className="w-full border-b border-t pt-4 border-font-edge dark:border-[#444444] dark:bg-black">
          <div className={'flex flex-wrap w-full justify-center gap-3 items-start max-w-2xl mx-auto mb-4'}>
            <Badge
              title={'All'}
              size={BadgeSizes.small}
              selected={activeFilters.length === 0}
              customClassName={'cursor-pointer'}
              customTextClassName='cursor-pointer !font-[500]'
              clickEvent={() => toggleFilter('all')}
            />
            {tagQuery.result?.map(tag => {

              return <Badge
                key={tag.scope}
                title={tag.name}
                image={tag.image_url}
                size={BadgeSizes.small}
                clickEvent={() => toggleFilter(tag.scope || '')}
                customClassName={'cursor-pointer'}
                customTextClassName='cursor-pointer !font-[500]'
                selected={tag.scope ? activeFilters.includes(tag.scope) : false}
              />;

            })}
            <Badge
              icon={addFillIcon}
              title={'Manage Preferences'}
              image={'/icons/settings.svg'}
              size={BadgeSizes.small}
              clickEvent={managePreferences}
              customClassName={'cursor-pointer'}
              customTextClassName='cursor-pointer !font-[500]'
            />
          </div>
        </div>
         */}
        <div className={'flex w-full flex-col justify-between items-start max-w-5xl mx-auto mb-4 px-2 mt-4'}>
          <Heading1 className={`ml-2 mb-4 text-action !font-extrabold !text-[32px] !leading-9 !tracking-tight !font-chivo`}>
            Local News
          </Heading1>
          <div className="flex-grow bg-white gap-y-4 dark:bg-surface-dark">
            <LocalArticles tags={tagQuery.result} />
          </div>
        </div>
      </div>
      <AppFooter />
    </>
  );
});

Local.displayName = 'Local';

export default Local;

