import BillingSubscription from '@/components/elements/BillingSubscription';
import BillingTransactions from '@/components/elements/BillingTransactions';

const Billing = () => {
  return (
    <div className="flex flex-col max-w-2xl w-full text-sm dark:text-white">
      <div className="text-2xl font-bold mb-6">Billing and Payments</div>
      <div className="max-w-md text-font-low mb-3">Manage your payment settings.</div>
      <BillingSubscription />
      <BillingTransactions />
    </div>
  );
};

Billing.displayName = 'Billing';

export default Billing;
