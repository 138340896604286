import { BodyLight, Button, ButtonSizes, ButtonVariants, Icon, Spacer } from '@roc-digital/ui-web';
import { appleIcon, facebookIcon, googleIcon, twitterIcon } from '@roc-digital/ui-lib';

export interface SocialSigninProps {
  onSignin?: (method: string) => void;
  mode?: string;
}

export  function SocialSignin(props: SocialSigninProps) {
  const navigate = (type: string) => {
    if(props.onSignin) {
      props.onSignin(type)
    }
    console.log('social sigin props', props);
  };

  return (
    <div className="flex-grow">
      <div className="flex flex-row items-center justify-center">
        <Spacer className="flex-grow" />
        <BodyLight className="px-2 work-sans dark:text-white">Sign {props.mode === 'login' ? 'in' : 'up'} with</BodyLight>
        <Spacer className="flex-grow" />
      </div>
      <div className="flex flex-row justify-center items-center my-4 gap-4">
        <Button
          id="google"
          clickEvent={() => navigate('google')}
          size={ButtonSizes.small}
          variant={ButtonVariants.white}
          customClassName="flex items-center justify-center bg-white rounded-full w-12 h-12 shrink-0 !px-0 py-0 border-font-muted"
        >
          <Icon src={googleIcon.toString()} />
        </Button>
        <Button
          id="facebook"
          clickEvent={() => navigate('facebook')}
          size={ButtonSizes.small}
          variant={ButtonVariants.white}
          customClassName="flex items-center justify-center bg-white rounded-full w-12 h-12 shrink-0 !px-0 py-0 border-font-muted"
        >
          <Icon src={facebookIcon.toString()} />
        </Button>
        {/* <Button
          id="twitter"
          clickEvent={() => navigate('twitter')}
          size={ButtonSizes.small}
          variant={ButtonVariants.white}
          customClassName="flex items-center justify-center bg-white rounded-full w-12 h-12 shrink-0 !px-0 py-0 border-font-muted"
        >
          <Icon src={twitterIcon.toString()} />
        </Button>
        <Button
          clickEvent={() => navigate('apple')}
          size={ButtonSizes.small}
          variant={ButtonVariants.white}
          customClassName="flex items-center justify-center bg-white rounded-full w-12 h-12 shrink-0 !px-0 py-0 border-font-muted"
        >
          <Icon src={appleIcon.toString()} />
        </Button> */}
      </div>
    </div>
  );
};

export default SocialSignin;
