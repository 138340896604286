import React from 'react';
import { publish } from '@roc-digital/mxm-base/events';

import { ClickEvent, CustomEvent, PublisherData, getClassNames } from '@roc-digital/ui-lib';

export type TextProps = {
  id?: string;
  size?: 'title' | 'body' | 'strong' | 'caption' | 'button' | 'small' | 'tiny' | 'custom';
  color?: 'default' | 'subdued' | 'disabled' | 'info' | 'success' | 'warning' | 'critical';
  disabled?: boolean;
  className?: string;
  clickEvent?: ClickEvent | CustomEvent;
  children: React.ReactNode;
};

const styleClasses = {
  base: ``,
  size: {
    title: 'font=font-semibold text-lg',
    body: `font-normal leading-6`,
    strong: `font-semibold leading-6`,
    caption: `font-normal leading-5 text-sm`,
    button: `font-medium leading-6`,
    small: `leading-5 text-sm`,
    tiny: `leading-4 text-xs`,
    custom: '',
  },
  color: {
    default: `text-admin-default`,
    subdued: `text-font-muted`,
    disabled: `text-font-light`,
    info: `text-font-info-light`,
    success: `text-font-success-high`,
    warning: `text-font-warning-high`,
    critical: `text-font-critical-high`,
  },
};

export const Text = ({ children, className, id, size, color, disabled, clickEvent }: TextProps) => {
  const publishClick = (evt: React.MouseEvent<HTMLSpanElement>) => {
    if (!clickEvent) {
      return;
    }

    if (clickEvent.allowDefault === false) {
      evt.preventDefault();
    }

    if (clickEvent.propagate === false) {
      evt.stopPropagation();
    }

    publish<PublisherData>(clickEvent.namespace, 'click', {
      id,
      eventData: clickEvent.data || {},
    });
  };

  if (disabled) {
    color = 'disabled';
  }

  const textClassName = getClassNames(styleClasses, { size, color });

  className = `${textClassName} ${className}`;

  return (
    <span id={id} className={className} onClick={publishClick}>
      {children}
    </span>
  );
};

Text.defaultProps = {
  size: 'body',
  color: 'default',
};
