import {  ArticleView } from './ArticleView';
import { ArticleContent, ArticleAction } from '@roc-digital/ui-lib';
// import { useAutoAnimate } from '@formkit/auto-animate/react';

export interface ZoneLayoutProps {
  layoutId: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 99;
  articles: ArticleContent[];
  onAction?: (action: ArticleAction) => void;
  ArticleViewComponent?: typeof ArticleView;
}
// const options = {
//   easing: 'ease-in',
//   duration: 1000
// }
export function ZoneLayout(props: ZoneLayoutProps) {
  // const [ref] = useAutoAnimate(options);

  let fragments = <></>;

  if(props.layoutId === 1) {
    fragments = <RenderArticleRange
      articles={props.articles}
      orientation='landscape'
      size='lg'
      start={0}
      end={1}
      onAction={props.onAction}
      className='flex-1'
      ArticleViewComponent={props.ArticleViewComponent}
    />
  } else if(props.layoutId === 2) {
    fragments = <RenderArticleRange
      articles={props.articles}
      orientation='portrait'
      size='md'
      start={0}
      end={2}
      className='flex-1'
      onAction={props.onAction}
      ArticleViewComponent={props.ArticleViewComponent}
    />
  } else if(props.layoutId === 3) {
    fragments = <RenderArticleRange
      articles={props.articles}
      orientation='portrait'
      size='md'
      start={0}
      end={3}
      className='flex-1 min-w-[150px]'
      onAction={props.onAction}
      ArticleViewComponent={props.ArticleViewComponent}
    />
  } else if(props.layoutId === 4) {
    fragments = <div className='flex flex-row gap-4 flex-wrap'>
      <div className='flex flex-row gap-4 flex-1'>
        <RenderArticleRange
          articles={props.articles}
          orientation='portrait'
          size='sm'
          start={0}
          end={1}
          onAction={props.onAction}
          className='flex-1 min-w-[230px]'
          ArticleViewComponent={props.ArticleViewComponent}
        />
      </div>
      <div className='flex flex-row gap-4 flex-1 flex-wrap'>
        <RenderArticleRange
          articles={props.articles}
          orientation='portrait'
          size='sm'
          start={1}
          end={3}
          onAction={props.onAction}
          className='flex-1 min-w-[230px]'
          ArticleViewComponent={props.ArticleViewComponent}
        />
      </div>
    </div>
  } else if(props.layoutId === 5) {
    fragments = <>
      <div className='flex flex-col gap-4'>
        <RenderArticleRange
          articles={props.articles}
          orientation='portrait'
          size='md'
          start={0}
          end={1}
          onAction={props.onAction}
          ArticleViewComponent={props.ArticleViewComponent}
        />
        <div className='flex flex-row gap-4'>
          <RenderArticleRange
            articles={props.articles}
            orientation='portrait'
            size='sm'
            start={2}
            end={4}
            onAction={props.onAction}
            ArticleViewComponent={props.ArticleViewComponent}
          />
        </div>
      </div>

      <div className='flex flex-col gap-4'>
        <RenderArticleRange
          articles={props.articles}
          orientation='portrait'
          size='md'
          start={4}
          end={5}
          onAction={props.onAction}
          ArticleViewComponent={props.ArticleViewComponent}
        />
        <div className='flex flex-row gap-4'>
          <RenderArticleRange
            articles={props.articles}
            orientation='portrait'
            size='sm'
            start={5}
            end={7}
            onAction={props.onAction}
            ArticleViewComponent={props.ArticleViewComponent}
          />
        </div>
      </div>
    </>
  } else if(props.layoutId === 6) {
    fragments = <RenderArticleRange
      articles={props.articles}
      orientation='portrait'
      size='sm'
      start={0}
      end={6}
      className='flex-1 min-w-[30%] flex-grow'
      onAction={props.onAction}
      ArticleViewComponent={props.ArticleViewComponent}
    />
  } else if(props.layoutId === 7) {
    fragments =  <>
      <div className='flex flex-col gap-4 flex-1'>
        <RenderArticleRange
          articles={props.articles}
          orientation='landscape'
          size='lg'
          start={0}
          end={1}
          onAction={props.onAction}
          ArticleViewComponent={props.ArticleViewComponent}
        />
        <div className='flex flex-row gap-4'>
          <RenderArticleRange
            articles={props.articles}
            orientation='portrait'
            size='sm'
            start={1}
            end={3}
            onAction={props.onAction}
            ArticleViewComponent={props.ArticleViewComponent}
          />
        </div>
      </div>
      <div className='flex flex-col items-stretch gap-4 max-w-[321px] flex-1'>
        <RenderArticleRange
          articles={props.articles}
          orientation='landscape'
          size='sm'
          start={3}
          end={6} 
          onAction={props.onAction}
          ArticleViewComponent={props.ArticleViewComponent}
        />
      </div>
    </>;
  } else if(props.layoutId === 8) {
    fragments = <RenderArticleRange
      articles={props.articles}
      orientation='landscape'
      size='sm'
      start={0}
      end={6}
      className="max-w-[496px] min-w-[275px] flex-1"
      onAction={props.onAction}
      ArticleViewComponent={props.ArticleViewComponent}
    />
  } else if(props.layoutId === 9) {
    fragments = <RenderArticleRange
      articles={props.articles}
      orientation='portrait'
      size='sm'
      start={0}
      end={9}
      className="max-w-[340px] min-w-[275px] flex-1"
      onAction={props.onAction}
      ArticleViewComponent={props.ArticleViewComponent}
    />
  } else if (props.layoutId === 99) {
    fragments = <div className="flex gap-4 flex-wrap"><RenderArticleRange
      articles={props.articles}
      orientation='landscape'
      size='sm'
      start={0}
      end={undefined}
      className="max-w-[275px] min-w-[275px] flex-1"
      onAction={props.onAction}
    /></div>
  }

  return <div className='flex flex-row gap-4 flex-wrap'>
    {fragments}
  </div>
}

export interface RenderArticleRangeProps {
 articles: ArticleContent[];
 start: number;
 end: number | undefined;
 orientation : 'portrait' | 'landscape',
 size: 'lg' | 'md' | 'sm';
 onAction?: (action: ArticleAction) => void;
 className?: string;
 ArticleViewComponent?: typeof ArticleView;
}

export function RenderArticleRange(props: RenderArticleRangeProps) {
  const articles = props.articles.slice(props.start, props.end);

  const Comp = props.ArticleViewComponent || ArticleView;
  return <>
    {articles.map((article, index) => <Comp
      key={(article.id || '_') + index}
      article={article}
      size={props.size}
      orientation={props.orientation}
      className={props.className}
      onAction={props.onAction}
      index={index}
    />)}
  </>
}