"use strict";
/* eslint-disable import/no-cycle */
// @copyright 2022 Navigo, Inc
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.putTag = exports.deleteTags = exports.myTags = exports.updateTag = exports.readTag = exports.createTag = exports.searchTagsV2 = exports.searchTags = exports.listMyTagsV2 = exports.listTagsV2 = exports.listLocationTags = exports.listTags = exports.followTag = void 0;
var data_1 = require("../data");
var http_1 = require("./http");
var config_1 = require("../config");
var json_fetch_1 = require("./json-fetch");
var TAGS_CACHE_TTL = 60 * 60 * 1000; // 1 hour
var SERVICE_NAME = 'tags';
var JSON_PATH = '_';
function url(isAdmin) {
    if (isAdmin === void 0) { isAdmin = false; }
    return isAdmin ? "".concat((0, config_1.getConfig)().mxmApiAdminUrl, "/tags") : "".concat((0, config_1.getConfig)().mxmApiWebUrl, "/tags");
}
function resolveTag() {
    return (0, http_1.createEntity)(JSON_PATH, data_1.Tag);
}
function resolveTagCollection() {
    return (0, http_1.createCollection)(JSON_PATH, data_1.Tags, data_1.Tag);
}
function followTag(cacheState, id, params) {
    return (0, http_1.resolveTextHTTPResponse)(SERVICE_NAME, 'followTag', (0, http_1.createHTTPRequest)('GET', "".concat(url(params === null || params === void 0 ? void 0 : params.isAdmin), "/").concat(id), params), function (res) {
        console.log(res);
        return { id: id };
    });
}
exports.followTag = followTag;
function listTags(type, params) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, 'listTags', (0, http_1.createHTTPRequest)('GET', "".concat(url(params === null || params === void 0 ? void 0 : params.isAdmin)), __assign({ type: type }, params)), (params === null || params === void 0 ? void 0 : params.isAdmin) ? (0, http_1.createPaginatedCollection)(JSON_PATH, data_1.Tags, data_1.Tag) : (0, http_1.createCollection)(JSON_PATH, data_1.Tags, data_1.Tag), true, TAGS_CACHE_TTL);
}
exports.listTags = listTags;
function listLocationTags(type, params) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, 'listLocationTags', (0, http_1.createHTTPRequest)('GET', "".concat(url(params === null || params === void 0 ? void 0 : params.isAdmin), "?type=location"), __assign({ type: type }, params)), (params === null || params === void 0 ? void 0 : params.isAdmin) ? (0, http_1.createPaginatedCollection)(JSON_PATH, data_1.Tags, data_1.Tag) : (0, http_1.createCollection)(JSON_PATH, data_1.Tags, data_1.Tag), true, TAGS_CACHE_TTL);
}
exports.listLocationTags = listLocationTags;
function listTagsV2(type, page, count) {
    return __awaiter(this, void 0, void 0, function () {
        var result, tags;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, json_fetch_1.ApiFetch)({
                        method: 'GET',
                        url: "".concat((0, config_1.getConfig)().mxmApiWebUrl, "/tags"),
                        query: {
                            type: type,
                            page: page,
                            count: count
                        }
                    })];
                case 1:
                    result = _a.sent();
                    tags = result.data || [];
                    tags.forEach(function (tag) {
                        if (!tag.scope) {
                            tag.scope = "".concat(tag.type, ":").concat(tag.tag);
                        }
                    });
                    return [2 /*return*/, tags];
            }
        });
    });
}
exports.listTagsV2 = listTagsV2;
function listMyTagsV2(type, params) {
    return __awaiter(this, void 0, void 0, function () {
        var result, tags;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, json_fetch_1.ApiFetch)({
                        method: 'GET',
                        url: "".concat((0, config_1.getConfig)().mxmApiWebUrl, "/my-tags").concat(type ? '?type=' + type : null)
                    })];
                case 1:
                    result = _a.sent();
                    tags = result.data || [];
                    tags.forEach(function (tag) {
                        if (!tag.scope) {
                            tag.scope = "".concat(tag.type, ":").concat(tag.tag);
                        }
                    });
                    return [2 /*return*/, tags];
            }
        });
    });
}
exports.listMyTagsV2 = listMyTagsV2;
function searchTags(term, params) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, 'searchTags', (0, http_1.createHTTPRequest)('GET', "".concat(url(params === null || params === void 0 ? void 0 : params.isAdmin)), __assign(__assign({}, params), { query: term })), (0, http_1.createCollection)(JSON_PATH, data_1.Tags, data_1.Tag), true, TAGS_CACHE_TTL);
}
exports.searchTags = searchTags;
function searchTagsV2(term, params) {
    return __awaiter(this, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, json_fetch_1.ApiFetch)({
                        url: "".concat(url(params === null || params === void 0 ? void 0 : params.isAdmin)),
                        query: __assign(__assign({}, params), { query: term })
                    })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, result.data || []];
            }
        });
    });
}
exports.searchTagsV2 = searchTagsV2;
function createTag(params) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, 'createTag', (0, http_1.createHTTPRequest)('POST', "".concat(url(true)), params, undefined, {
        'Content-Type': 'application/json',
    }), resolveTag(), true, TAGS_CACHE_TTL);
}
exports.createTag = createTag;
function readTag(id, params) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, 'readTag', (0, http_1.createHTTPRequest)('GET', "".concat(url(true), "/").concat(id), params, undefined, {
        'Content-Type': 'application/json',
    }), resolveTag(), true, TAGS_CACHE_TTL);
}
exports.readTag = readTag;
function updateTag(id, params) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, 'updateTag', (0, http_1.createHTTPRequest)('PATCH', "".concat(url(true), "/").concat(id), params, undefined, {
        'Content-Type': 'application/json',
    }), resolveTag(), true, TAGS_CACHE_TTL);
}
exports.updateTag = updateTag;
function myTags(type, params) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, 'myTags', (0, http_1.createHTTPRequest)('GET', "".concat((0, config_1.getConfig)().mxmApiWebUrl, "/my-tags"), { type: type, params: params }), resolveTagCollection(), true, TAGS_CACHE_TTL);
}
exports.myTags = myTags;
function deleteTags(id, params) {
    return (0, http_1.resolveTextHTTPResponse)(SERVICE_NAME, 'deleteTags', (0, http_1.createHTTPRequest)('DELETE', "".concat(url(params === null || params === void 0 ? void 0 : params.isAdmin), "/").concat(id), params), function () { return ({ id: id }); });
}
exports.deleteTags = deleteTags;
function putTag(tag) {
    if (tag === null || tag === void 0 ? void 0 : tag.id) {
        return updateTag(tag.id, tag);
    }
    else {
        return createTag(tag);
    }
}
exports.putTag = putTag;
