import React, { useEffect, useMemo, useState } from 'react';
import { Articles } from '@roc-digital/mxm-base/data';
import { useMethod, mapToArticleContent, getArticleActionHandler, useStateEvent } from '@roc-digital/ui-lib';
import { Button, ButtonSizes, Loading, ZoneLayout } from '@roc-digital/ui-web';
import { bookmarkArticle, handleVote, handleBookmark, listHomeArticles, listArticlesByScopesV2, listArticlesNearMe } from '@roc-digital/mxm-base/logic';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

type Props = {
  tags?: any;
};

const LocalArticles = (props: Props) => {
    const navigate = useNavigate();
    const articlesQuery = useMethod(listArticlesNearMe);
    const [loading, setLoading] = useState(true);
    console.log(loading);
    const [localScopes, setLocalScopes] = useState([] as string[]);
    const articleMaps = React.useMemo(() => {
      const items = articlesQuery.result?.map((article) => mapToArticleContent(article)) || [];
      if (items.length > 0) {setLoading(false);}
      
      return {
        top: items.slice(0,3),
        mid: items.slice(3,6),
        bottom: items.slice(6,12),
        end: items.slice(12)
      }
    }, [articlesQuery.result]);
    
    const onAction = useMemo(() => {
      return getArticleActionHandler({
        get: articlesQuery.getLastResult as any,
        set: articlesQuery.setResult,
        navigate: async (article) => navigateAndScroll(article.id, createSlug(article.title)),
        bookmark: async (article) => {
          try {
            const result = await handleBookmark(article);
            if(result.bookmarked) {
              toast.success('Article bookmarked');
            } else {
              toast.success('Bookmark removed');
            }
            return result;
          } catch(err) {
            console.error(err);
            toast.error('Failed to bookmark article')
            return article;
          }
        },
        vote: (article, vote) =>  handleVote(article.id, vote),
      });
    }, []);
  
    const createSlug = (title: string) => {
      const lowercaseText = title.toLowerCase();
      const formattedText = lowercaseText.replace(/[^a-z0-9]+/g, '-');
      const slug = formattedText.replace(/^-+|-+$/g, '');
      return slug;
    };

    const navigateAndScroll = (id: string, slug?: string) => {
      navigate(`/article/${id}${ slug ? '?' + slug : null}`);
      window.scrollTo(0, 0);
    }
  
    const locations = (tags: any) => {
      const scopes: string[] = [];
      if (tags) {
        tags.forEach((tag: { type: string; scope: string; }) => {
          if (tag.type === 'location') {
            scopes.push(tag.scope as string)
          }
        });
      } 
      console.log('Scopes', scopes);
      setLocalScopes(scopes);
      return scopes;
    }

    useEffect(() => {
      const scopes = locations(props.tags); //props.tags?.map((z: { scope: any; }) => z.scope) as string[] || [];
      console.log(scopes);
      if (scopes.length > 0 || true) {
        articlesQuery.run(['feed:near-me'], 1, 20);
      }
    }, []);
  
    return (
      <div className="w-full flex flex-col max-w-5xl mx-auto px-2">
        {articleMaps.top.length > 0
          ? 
        <>
          <ZoneLayout articles={articleMaps.top} layoutId={3} onAction={onAction}/>
          <span className={'mb-4 block'} />
          <ZoneLayout articles={articleMaps.mid} layoutId={3} onAction={onAction}/>
          <span className={'mb-4 block'} />
          <ZoneLayout articles={articleMaps.bottom} layoutId={8} onAction={onAction}/>
          <span className={'mb-4 block'} />
          <ZoneLayout articles={articleMaps.end} layoutId={8} onAction={onAction}/>
        </>
        : 
          (loading ? <div className="w-full"><Loading className='mx-auto'/></div> : <>
            <div className="dark:text-white">
              No local articles available for your selected locations.
              <span className={'mb-4 block'} />
              <Button size={ButtonSizes.medium} clickEvent={() => navigate('/account/locations')}>Select More Locations</Button>
            </div>
          </>)
          
        }
      </div>
    );
};

export default LocalArticles;
