import { LoginOptions } from '@/logic';
import { useState } from 'react';
import { Button, Heading1, Icon, Input } from '@roc-digital/ui-web';
import {
  InputData,
  mxmIcon,
  useChangeEvent,
  useClickEvent,
  eyeOpenIcon,
  eyeClosedIcon,
} from '@roc-digital/ui-lib';
import { Event } from '@roc-digital/mxm-base/dist/types';
import SocialSignin from '@/components/views/Login/SocialSignin';
import { Loading } from '@/components/elements/Loading';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

export interface LoginFormProps {
  mode: 'login' | 'sign-up';
  onLogin: (value: LoginOptions) => void;
  onContinueAsGuest?: () => void;
  onForgotPassword?: () => void;
  loading?: boolean;
}

export function LoginForm(props: LoginFormProps) {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [username, setUsername] = useState('');

  const handleEmailChange = useChangeEvent(
    ({ data }: Event<InputData>) => {
      setEmail(data.value as string);   
    },
    {},
    [],
    [email]
  );

  const handlePasswordChange = useChangeEvent(
    ({ data }: Event<InputData>) => {
      setPassword(data.value as string);
    },
    {},
    [],
    []
  );

  const handleLoginClick = () => {
    props.onLogin({
      method: 'password-sign-in',
      email: email,
      password: password
    })
  }

  const handleSignupClick = () => {
    if (!validateEmail(email)) {
      toast.error('Invalid email format.');
      return;
    }
    if (password !== confirmPassword) {
      console.log('Passwords do not match.');
      toast.error('Your passwords to not match.');
      return;
    }
    props.onLogin({
      method: 'password-signup',
      email: email,
      password: password
    })
  }

  function validateEmail(email: string) {
    const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    return emailRegex.test(email);
  }

  const handleFormSubmit = (event: any) => {
    event.preventDefault();
    handleLoginClick();
  };

  const showPasswordEvent = useClickEvent(
    () => {
      setShowPassword(!showPassword);
    },
    undefined,
    undefined,
    [showPassword]
  );

  const handleConfirmPasswordChange = useChangeEvent(
    ({ data }: Event<InputData>) => {
      setConfirmPassword(data.value as string);
    },
    {},
    [],
    [confirmPassword]
  );

  const handleUsernameChange = useChangeEvent(
    ({ data }: Event<InputData>) => {
      setUsername(data.value as string);
    },
    {},
    [],
    [username]
  );

  const loginForm = <form onSubmit={handleFormSubmit}>
    {showPassword ? (
      <Input
        key={'show-passoword'}
        type="text"
        value={password}
        changeEvent={handlePasswordChange}
        placeholder="Password"
        className="w-full mb-3 px-3 py-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm pr-12 font-worksans !text-base"
      />
    ) : (
      <Input
        key={'hide-password'}
        type="password"
        value={password}
        changeEvent={handlePasswordChange}
        placeholder="Password"
        className="w-full mb-3 px-3 py-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm pr-12 font-worksans !text-base"
      />
    )}
  </form>;

  const buttonText = props.mode == 'login' ? 'Sign In' : 'Sign Up';

  return <>
    <div className="flex items-center">
      <div className="mx-auto flex items-center">
        <Icon src={mxmIcon} size="x-large" />
        <Heading1 className="text-3xl text-admin-dark font-chivo font-bold dark:text-white pl-4">MxM News</Heading1>
      </div>
    </div>
    <div className="w-full mt-4">
      <Input
        type="email"
        value={email}
        changeEvent={handleEmailChange}
        placeholder="Email Address"
        className="w-full mb-3 px-3 py-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm font-worksans !text-base sm:pr-12"
      />
    </div>
    {props.mode === 'sign-up' ? <>
      <div className="w-full mt-4">
        <Input
          type="text"
          value={username}
          changeEvent={handleUsernameChange}
          placeholder="Username"
          className="w-full mb-3 px-3 py-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm font-worksans !text-base"
        />
      </div>
    </> : <></>}
    <div className="w-full mt-4 relative">
      {loginForm}
      <Icon
        src={showPassword ? eyeClosedIcon : eyeOpenIcon}
        className="absolute right-4  top-[20px] cursor-pointer"
        clickEvent={showPasswordEvent}
      />
    </div>
    {props.mode === 'sign-up' ? <>
      <div className="w-full mt-4 relative">
        {showPassword ? (
          <Input
            key="confirm-password-show"
            type="text"
            value={confirmPassword}
            changeEvent={handleConfirmPasswordChange}
            placeholder="Repeat Password"
            className="w-full mb-3 px-3 py-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm font-worksans !text-base"
          />
        ) : (
          <Input
            key="confirm-password-hide"
            type="password"
            value={confirmPassword}
            changeEvent={handleConfirmPasswordChange}
            placeholder="Repeat Password"
            className="w-full mb-3 px-3 py-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm font-worksans !text-base"
          />
        )}
        <Icon
          src={showPassword ? eyeClosedIcon : eyeOpenIcon}
          className="absolute right-4 top-[20px] cursor-pointer"
          clickEvent={showPasswordEvent}
        />
      </div>
    </> : <></>}


    {props.mode === 'login' ? <>
      <div className="w-full pt-2 pb-4 sm:w-auto">
        <span onClick={() => navigate('/forgotpassword')} className="text-md text-font-info dark:text-gray-100 cursor-pointer">
          Forgot Password
        </span>
      </div>
    </> : <></>}

    <div className="w-full">
      <Button clickEvent={props.mode === 'login' ? handleLoginClick : handleSignupClick}>
        {props.loading ? <Loading color={'white'} customClassName={'ml-auto mr-auto'} /> : buttonText}
      </Button>
    </div>
    <div className={'w-full p-5 my-6'}>
      <SocialSignin mode={props.mode} onSignin={(app) => props.onLogin({method: 'social', app: app as any})}/>
    </div>
    
    {props.mode === 'login' ? <>
      <span className="h-14 flex items-center justify-center w-full font-worksans dark:text-white cursor-pointer" onClick={() => navigate('/signup')}>
        Need an account?
        <span className="font-medium ml-2">Sign Up</span>
        <span className="text-font-info-high ml-2 dark:text-white">{'>'}</span>
      </span>
    </> : <>
      <span className="h-14 flex items-center justify-center w-full font-worksans dark:text-white cursor-pointer" onClick={() => navigate('/login')}>
        Already Registered?
        <span className="font-medium ml-2">Log In</span>
        <span className="text-font-info-high ml-2">{'>'}</span>
      </span>
    </>}

    <span onClick={() => navigate('/trending')} className="h-14 flex items-center justify-center w-full font-medium font-worksans dark:text-white cursor-pointer">
      Continue as Guest
      <span className="text-font-info-high ml-2 dark:text-white">{'>'}</span>
    </span>
  </>;
}