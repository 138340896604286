import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { Tags } from '@roc-digital/mxm-base/data';
import { SessionState } from '@roc-digital/mxm-base/state';
import { Button, ButtonSizes, Icon, InputSearch, Pressable } from '@roc-digital/ui-web';
import { Event } from '@roc-digital/mxm-base/types';
import {
  Authentication,
  PublisherData,
  localIcon,
  localFilledIcon,
  starIcon,
  starFillIcon,
  trendingIcon,
  trendingFillIcon,
  useClickEvent,
  useCustomEvent,
  useStateEvent,
} from '@roc-digital/ui-lib';
import { signoutWithFirebase } from '@/logic';
import { Search } from './Search';

type Props = {
  isLoggedOut?: boolean;
};

const Header = ({ isLoggedOut }: Props) => {
  const navigate = useNavigate();
  const [tags] = useStateEvent<Tags>('mxm.http.tags', 'listTags.success', undefined);
  const [search, setSearch] = useState<string>('');
  const [authState, setAuthState] = useState<Authentication>({
    isAuthenticated: false,
  });

  useEffect(() => {}, []);

  const handleMenuClick = useClickEvent(({ data }: Event<PublisherData>) => {
    if (data.id === 'login') {
      navigate('/login');
    }
    if (data.id === 'signup') {
      navigate('/signup');
    }
    if (data.id === 'logout') {
      signoutWithFirebase(SessionState);
      setAuthState({ isAuthenticated: false });
      navigate('/');
    }
    navigate(data?.id || ('/' as string));
  });

  const handleSearchSelect = useCustomEvent(
    'selected',
    ({ data }: { data: any }) => {
      navigate(`/trending/${data.selectedItem.toLowerCase()}`);
      console.log('search', data);
      setSearch(data.selectedItem);
    },
    { namespace: 'mxm.search' },
    { namespace: 'mxm.search' },
    [search]
  );

  const handleSearchChange = useCustomEvent(
    'change',
    ({ data }: { data: any }) => {
      if (data.value === '') {
        setSearch('');
      }
    },
    { namespace: 'mxm.search' },
    { namespace: 'mxm.search' },
    [search]
  );

  const handleYourAccountClick = useClickEvent(
    () => {
      navigate('/account/profile');
    },
    {},
    [],
    [authState]
  );

  const handleHomeClick = () => {
    navigate('/');
  };

  const inputSearch = (
    <Search/>
  );

  const loginBtn = (
    <Button
      title={'Login'}
      id={'login'}
      size={ButtonSizes.medium}
      customClassName={'bg-white border-2 border-[#24AAE1] mr-4 medium-height'}
      customTextClassName={'text-[#1E222A] '}
      clickEvent={handleMenuClick}
    />
  );
  const signupBtn = (
    <Button
      title={'Sign Up'}
      id={'signup'}
      size={ButtonSizes.medium}
      customClassName={'medium-height'}
      clickEvent={handleMenuClick}
    />
  );
  const logo = (
    <img
      onClick={handleHomeClick}
      alt={'logo'}
      src={'/icons/logo.svg'}
      className={'cursor-pointer h-10 w-10 mx-0 sm:mx-2'}
    />
  );

  const accountLink = (
    <span className={'flex items-center sm:w-1/2 md:w-1/4 justify-end'}>
      <Pressable clickEvent={handleYourAccountClick}>
        <span className="text-md font-normal text-[#1E222A] dark:text-white">Your Account</span>
      </Pressable>
    </span>
  );

  const trendingLink = (
    <span className={'flex items-center mx-4 dark:text-white'}>
      <Link to={'/trending'} className={'flex items-center text-md font-normal text-[#1E222A] dark:text-white'}>
        <Icon
          src={location.pathname.includes('trending') ? trendingFillIcon : trendingIcon}
          className={'w-5 h-5 mr-2 ml-4 sm:ml-0'}
        />
        <div className='inline-block'>Trending</div>
      </Link>
    </span>
  );

  const forYouLink = (
    <span className={'flex items-center mx-4'}>
      <Link to={'/foryou'} className={'flex items-center text-md font-normal text-[#1E222A] dark:text-white'}>
        <Icon src={location.pathname.includes('foryou') ? starFillIcon : starIcon} className={'w-5 h-5 mr-2'} />
        <div className='inline-block'>For You</div>
      </Link>
    </span>
  );

  const localLink = (
    <span className={'flex items-center mx-4'}>
      <Link to={'/local'} className={'flex items-center text-md font-normal text-[#1E222A] dark:text-white'}>
        <Icon src={location.pathname.includes('local') ? localIcon : localFilledIcon} className={'w-5 h-5 mr-2'} />
        <div className='inline-block'>Local</div>
      </Link>
    </span>
  );

  return (
    <>
      <div className="container mx-auto flex visible-md">
        <div className="w-full flex place-content-around items-center relative">
          {logo}
          {inputSearch}
          {isLoggedOut && <span className="ml-2">{loginBtn}</span>}
          {!isLoggedOut && (
            <Pressable clickEvent={handleYourAccountClick}>
              <img src={'/icons/account.svg'} className="w-5 h-5 mx-4" />
            </Pressable>
          )}
        </div>
        <div className="w-full flex place-content-center items-center my-4">
          {trendingLink}
          {forYouLink}
          {localLink}
        </div>
      </div>
      <div className="container mx-auto w-[1008px] flex hidden-md mb-2">
        <div className="w-1/2 flex items-center">
          {logo}
          {trendingLink}
          {forYouLink}
          {localLink}
        </div>
        <div className="w-1/2 flex space-x-4 justify-end relative">
          {inputSearch}
          {isLoggedOut && (
            <>
              {loginBtn}
              {signupBtn}
            </>
          )}
          {!isLoggedOut && accountLink}
        </div>
      </div>
    </>
  );
};

Header.displayName = 'Header';

export default Header;
