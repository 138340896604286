import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMethod } from '@roc-digital/ui-lib';
import { LoginAndCheckAccount } from '@/logic';
import { LoginForm, LandingPageView } from '@/components/elements';
import { UserOnboardingFlow } from './UserOnboardingFlow';
import { useAuthState } from '@/logic';
import { ForgotPasswordForm } from '@/components/elements/ForgotPasswordForm';

export interface SignInProps {
  loginMode: 'login' | 'sign-up';
}

export function SignIn(props: SignInProps) {
  const auth = useAuthState();

  const loginQuery = useMethod(LoginAndCheckAccount, {
    onError: (err) => {
      toast.error(err.toString() || 'Incorrect username or password.');
    }
  });

  let content = <></>;

  if(auth.loading || auth.authenticating) {
    content = <>
      <div style={{width: '320px', height: '500px'}}></div>
    </>;
  } else if(!auth.authenticated || auth.isAnonymous) {
      content = <LoginForm
        mode={props.loginMode}
        loading={loginQuery.loading}
        onLogin={loginQuery.run}
      />
  } else if(!auth.onBoarded) {
    content = <UserOnboardingFlow/>;
  } else {
    content = <Navigate to="/trending"/>
  }

  return <LandingPageView>
    {content}
  </LandingPageView>;
}
