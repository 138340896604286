import { useEffect, useState } from 'react';
import { useStateEvent, useClickEvent, InputData, useCustomEvent } from '@roc-digital/ui-lib';
import { Button, ButtonSizes, ButtonVariants, Modal } from '@roc-digital/ui-web';
import { listCharges } from '@/logic/payments';

const BillingTransactions = () => {
  const [charges, setCharges] = useState<any>(null);
  const [customerId, setCustomerId] = useState<string>('cus_OsW0gqCt20EMeV');
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    loadCharges();
  }, []);

  const loadCharges = async () => {
    try {
      const charges = await listCharges({customerId: customerId});
      setCharges(charges.chargesList);
      console.log(charges.chargesList);
    } catch (error: any) {
      setError(error.message);
    }
  };

  const transactionDetails = (charge: any) => {
    return <div className="p-12">
      <div className="text-xl font-bold">Transaction Details</div>
      <div className="mt-2"><b>Amount:</b> {charge.amount}</div>
      <div className="mt-2"><b>Date:</b> {unixTimestampToLocal(charge.created)}</div>
      <div className="mt-2"><b>Charge for:</b> MXM Exclusive</div>
      <div className="mt-2"><b>View Invoice:</b> <a href={charge.receipt_url} target='_BLANK'>Invoice</a></div>
      <hr className="mt-4"/>
      <div className="mt-6 mx-auto w-[80px]">
        <Button variant={ButtonVariants.square} size={ButtonSizes.medium} clickEvent={() => Modal.close('mxm.modal')}>Close</Button>
      </div>
    </div>;
  };
  
  const showDetails = (charge: any) => {
    Modal.open('mxm.modal', transactionDetails(charge), "small")
  };

  const unixTimestampToLocal = (unixTimestamp: any) => {
    const date = new Date(unixTimestamp * 1000);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const localTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  
    return localTime;
  }

  return (
    <div>
      <div className="font-bold pb-2 text-lg mt-8">Transaction History</div>
      <hr />
      {charges && charges.length > 0 ? charges.map((charge: any) => {
        return <div className="flex inline items-center pt-2">
          {charge.amount === 0 ?
            <span>$0 charged on {unixTimestampToLocal(charge.created)} for MXM Exclusive trial period</span>
          : 
            <span>${charge.amount} successfully charged on {unixTimestampToLocal(charge.created)} for MXM Exclusive</span>
          }
          <Button size={ButtonSizes.small} variant={ButtonVariants.plain} customClassName="ml-6" clickEvent={() => showDetails(charge)}>Details</Button>
        </div>;
      }) : <div className="pt-4">No charges have been made to this customer.</div>}
    </div>
  );
};

BillingTransactions.displayName = 'BillingTransactions';

export default BillingTransactions;
