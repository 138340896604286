import { memo } from 'react';
import AppFooter from '@/components/elements/AppFooter';

const About = memo(() => {
  return (
    <div className="h-full flex flex-col justify-center bg-gray-100 font-chivo overflow-x-hidden dark:bg-surface-dark">
      <div className="p-5 mt-10 mb-20 mx-auto max-w-5xl">
        <h1 className={'text-3xl dark:text-white'}>About</h1>
        <div className={'pt-4 dark:text-white'}>
          MxM News delivers curated news that cut through the censorship, mainstream bias, and institutional dominance
          that has left society divided and misinformed. The platform allows readers to access the news that matters,
          particularly when it is being ignored. Updated minute-by-minute with news coverage from a diversity of
          publications and topics, readers have a one stop to be informed and stay informed.
        </div>
        <div className={'pt-4 dark:text-white'}>
          Our name refers to &apos;minute by minute&apos;, and the app updates minute-by-minute with news coverage from
          a diversity of publications and topics.
        </div>
        <div className={'pt-4 dark:text-white'}>
          The app is free to download and use and includes a paid subscription for access to exclusive content.
        </div>
      </div>
      <AppFooter />
    </div>
  );
});

About.displayName = 'About';

export default About;
