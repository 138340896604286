import React, { useEffect, useState } from 'react';
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';

const Subscription = () => {
  const [stripePromise, setStripePromise] = useState<any>(null);
  
  useEffect(() => {
    import('@stripe/stripe-js')
      .then(({ loadStripe }) => {
        const stripePromise = loadStripe('pk_test_51NNcqHCI88K9NYrQKoBPo18CYYHJmukyi6pqvHWbuG63yJdXeUrtwoIcUcf6NxddRnOEYGLlr7LJiQqSRMlMxqsj00lnleZ4Vj');    
        setStripePromise(stripePromise);
      })
      .catch(err => {
        console.log('Failed to load Stripe', err);
      });
  }, []);
  
  const options: any = {
    mode: 'payment',
    amount: 8,
    currency: 'usd',
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  return (
    <div className="p-8 border">
      {stripePromise ? 
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm />
        </Elements>
      : 'Loading Stripe...'}
    </div>
  );
};

export default Subscription;