import React, { memo, useEffect, useState, useMemo } from 'react';
import { bookmarkArticle, handleBookmark, handleVote, listArticlesForMeV2, listMyTagsV2 } from '@roc-digital/mxm-base/logic';
import { Badge, Heading1, ZoneLayout, BadgeSizes, Modal } from '@roc-digital/ui-web';
import {
  followingIcon,
  politicsIcon,
  financeMoneyIcon,
  policyIcon,
  internationalIcon,
  americaFirstIcon,
  lifeCultureIcon,
  addFillIcon,
  useMethod,
  mapToArticleContent,
  getArticleActionHandler,
} from '@roc-digital/ui-lib';
import { useNavigate } from 'react-router';
import AppFooter from '@/components/elements/AppFooter';
import { Loading } from '@/components/elements/Loading';
import { toast } from 'react-toastify';
import LocalArticles from '@/components/elements/LocalArticles';
import { ArticleSchema } from '@roc-digital/mxm-base/src/data';
import ShareThis from '@/components/elements/ShareThis';

const ForYou = memo(() => {
  const navigate = useNavigate();
  const [activeFilters, setActiveFilters] = useState<string[]>([]);
  const articlesQuery = useMethod(listArticlesForMeV2);
  const tagQuery = useMethod(listMyTagsV2);

  useEffect(() => {
    tagQuery.run('category');
  }, []);

  useEffect(() => {
    void articlesQuery.run(activeFilters, 1, 50);
  }, [activeFilters]);

  const articleMaps = React.useMemo(() => {
    const items = articlesQuery.result?.map((article) => mapToArticleContent(article)) || [];

    return {
      top: items.slice(0,2),
      mid: items.slice(2,5),
      bottom: items.slice(5,11),
      end: items.slice(11)
    }
  }, [articlesQuery.result]);

  const onAction = useMemo(() => {
    return getArticleActionHandler({
      get: articlesQuery.getLastResult,
      set: articlesQuery.setResult,
      navigate: async (article) => navigate(`/article/${article.id}?${createSlug(article.title)}`),
      bookmark: async (article) => {
        try {
          const result = await handleBookmark(article);
          if(result.bookmarked) {
            toast.success('Article bookmarked');
          } else {
            toast.success('Bookmark removed');
          }
          return result;
        } catch(err) {
          console.error(err);
          toast.error('Failed to bookmark article')
          return article;
        }
      },
      vote: (article, vote) =>  handleVote(article.id, vote),
      share: (article) => openShareModal(article)
    });
  }, []);

  const createSlug = (title: string) => {
    const lowercaseText = title.toLowerCase();
    const formattedText = lowercaseText.replace(/[^a-z0-9]+/g, '-');
    const slug = formattedText.replace(/^-+|-+$/g, '');
    return slug;
  };

  const openShareModal = (article: ArticleSchema) => {
    const url = window.location.host  + '/article/' + article.id;
    const title = article.title.length > 140 ? article.title.substring(0,140) + '...' : article.title;
    Modal.open('mxm.modal', <ShareThis url={url} title={title}/>, 'small');
  };

  const managePreferences = React.useCallback(() => {
    navigate('/account/interests');
  }, []);

  const toggleFilter = React.useCallback((id: string) => {
    setActiveFilters(activeFilters => {
      if(id === 'all') {
        return [];
      }

      let next: string[] = [];
      if (activeFilters.includes(id)) {
        next = activeFilters.filter((item) => item !== id);
      } else {
        next = [...activeFilters, id];
      }

      next = next.filter((a, i, l) => l.indexOf(a) == i)
      if(next.length > 1) {
        next = next.filter(a => a !== 'all')
      }

      return next;
    });
  }, [])

  return (
    <>
      <div className="h-full w-full flex flex-col justify-center mx-auto pt-0 dark:bg-surface-dark flex-grow">
        <div className="w-full border-b border-t pt-4 border-font-edge dark:border-[#444444] dark:bg-black">
          <div className={'flex flex-wrap w-full justify-center gap-3 items-start max-w-2xl mx-auto mb-4'}>
            <Badge
              title={'All'}
              size={BadgeSizes.small}
              selected={activeFilters.length === 0}
              customClassName={'cursor-pointer'}
              customTextClassName='cursor-pointer !font-[500]'
              clickEvent={() => toggleFilter('all')}
            />
            {tagQuery.result?.map(tag => {

              return <Badge
                key={tag.scope}
                icon={tagIconMap[tag.tag || '']}
                title={tag.name}
                image={tag.image_url}
                size={BadgeSizes.small}
                clickEvent={() => toggleFilter(tag.scope || '')}
                customClassName={'cursor-pointer'}
                customTextClassName='cursor-pointer !font-[500]'
                selected={tag.scope ? activeFilters.includes(tag.scope) : false}
              />;

            })}
            <Badge
              icon={addFillIcon}
              title={'Manage Preferences'}
              image={'/icons/settings.svg'}
              size={BadgeSizes.small}
              clickEvent={managePreferences}
              customClassName={'cursor-pointer'}
              customTextClassName='cursor-pointer !font-[500]'
            />
          </div>
        </div>
        <div className={'flex w-full flex-col justify-between items-start max-w-5xl mx-auto mb-4 px-2 mt-4'}>
          <Heading1 className={`text-action !font-extrabold !text-[32px] !leading-9 !tracking-tight !font-chivo`}>
            Your News
          </Heading1>
          {articlesQuery.loading && (
            <div className={'w-full align-center absolute h-[0px]'}>
              <Loading customClassName={'ml-auto mr-auto'} />
            </div>
          )}
        </div>
        <div className={'w-full flex flex-col mb-12 max-w-5xl mx-auto'}>
          <div className="flex-grow bg-white gap-y-4 dark:bg-surface-dark mx-2">
            <ZoneLayout articles={articleMaps.top} layoutId={2} onAction={onAction}/>
            <span className={'mb-4 block'} />
            <ZoneLayout articles={articleMaps.mid} layoutId={3} onAction={onAction}/>
            <span className={'mb-4 block'} />
            <ZoneLayout articles={articleMaps.bottom} layoutId={8} onAction={onAction}/>
            <span className={'mb-4 block'} />
            <ZoneLayout articles={articleMaps.end} layoutId={8} onAction={onAction}/>
          </div>
        </div>
      </div>
      <AppFooter />
    </>
  );
});

ForYou.displayName = 'Mymxm';

export default ForYou;

const tagIconMap: any = {
  following: followingIcon,
  politics: politicsIcon,
  finance: financeMoneyIcon,
  policy: policyIcon,
  international: internationalIcon,
  'america-first': americaFirstIcon,
  'life-and-culture': lifeCultureIcon,
}