import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { createSubscription } from "@/logic/payments";
import { Button, ButtonSizes } from "@roc-digital/ui-web";

const CheckoutForm = () => {
  const [error, setError] = useState<any>(null);
  const [success, setSuccess] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState<string>('');
  const [plan, setPlan] = useState<any>('price_1O4ammCI88K9NYrQdHkOkxVF');

const handleSubmit = async (event: any) => {
  event.preventDefault();
  if (!stripe || !elements) {
    return;
  }

  const cardElement: any = elements.getElement(CardElement);

try {
  const { error, paymentMethod } = await stripe.createPaymentMethod({
    type: "card",
    card: cardElement,
  });
  console.log(event.email);

  if (error) {  
    setError(error.message);
    return;
  }

  const { id } = paymentMethod;
  const response = await createSubscription({
    email: email,
    plan: plan,
    stripeToken: id,
  });

  if (response.error) {
    setError(response.error);
    return;
  }

  setSuccess(true);
} catch (error: any) {
  setError(error.message);
}
};

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" required className="p-2 border rounded-md w-full"/>
      </div>
      <select name="plan" value={plan} onChange={(e) => setPlan(e.target.value)} required className="w-full p-2 rounded-md mt-2 mb-2">
        <option value="price_1O4ammCI88K9NYrQdHkOkxVF">Monthly Subscription ($8/mo)</option>
        <option value="price_1O4ao1CI88K9NYrQj1bWwUkO">Annual Subscription ($80/year)</option>
      </select>
      <CardElement className="p-2" />
      {error && <p style={{ color: "red" }}>{error}</p>}
      {success && <p style={{ color: "green" }}>Subscription created!</p>}
      <Button clickEvent={handleSubmit} disabled={!stripe} customClassName="mt-6">
        Subscribe
      </Button>
    </form>
  );
};

export default CheckoutForm;