import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { getConfig, loadJsonConfig } from '@roc-digital/mxm-base/config';
import { initHttp, initI18n, setEventSource } from '@roc-digital/mxm-base/logic';
import { CacheState, SessionState, MocksState } from '@roc-digital/mxm-base/state';
import { publish } from '@roc-digital/mxm-base/events';
import { App } from '@/components/App';
import reportWebVitals from './reportWebVitals';

import '@roc-digital/ui-web/index.css';
import './index.css';

const loadUserLanguage = async () => {
  const sessionStateLanguage = await SessionState.getLanguage();

  const systemLanguage = navigator.language.split('-')[0];

  if (sessionStateLanguage) {
    return sessionStateLanguage === 'system' ? systemLanguage : sessionStateLanguage;
  }

  // const user = await SessionState.getUser();
  const currentLanguage = 'en';

  await SessionState.setLanguage(currentLanguage);

  return currentLanguage;
};

loadJsonConfig({
  env: 'qa',
  logging: false,
  mocking: false,
  ssoUrl: 'https://qa-login.mxm.global/auth',
  ssoClientId: 'qa-mxm-platform',
  ssoRealm: 'mxm',
  cdnHost: 'https://api.qa-mxmnews.com',
  mxmApiWebUrl: process.env.REACT_APP_WEB_API_URL || '',
  mxmApiDataUrl: process.env.REACT_APP_WEB_API_URL || '',
  mxmApiAdminUrl: process.env.REACT_APP_ADMIN_API_URL || '',
  appVersion: '1.0.0',
  appDevice: 'web',
  app: undefined,
  mockingUser: '',
}).then(async () => {
  setEventSource(EventSource);

  CacheState.init(localStorage);
  SessionState.init(sessionStorage);

  const userLanguage = await loadUserLanguage();

  initI18n(userLanguage, () => {
    publish('mxm.i18n', 'initialize.success');
  });

  initHttp(CacheState, SessionState, MocksState);

  const container = document.getElementById('root');

  if (!container) {
    throw new Error('No root tag to mount React.');
  }

  const root = createRoot(container);

  root.render(
    // <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    // </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
