import { useEffect, useState } from 'react';
import { useStateEvent, useClickEvent, InputData, useCustomEvent } from '@roc-digital/ui-lib';
import { Button, ButtonSizes, ButtonVariants, Input, Modal } from '@roc-digital/ui-web';
import Subscription from './Subscription';

const BillingSubscription = () => {
  const [loading, setLoading] = useState(false);

  const cancelSubscription = () => {
    if (!confirm('Are you sure you want to cancel your subscription?')) { return; }
  };

  const deleteCard = () => {
    alert('You cannot delete your payment method if you have an active subscription. Please instead update your payment method.');
    if (!confirm('Are you sure you want to delete your payment method?')) { return; }
  };

  const paymentCardForm = <div className="p-12 dark:bg-black dark:text-white">
    <div className="text-xl w-full font-bold mb-2">Update Payment Card Details</div>
    <Subscription />    
  </div>;

const openPaymentModal = () => {
  Modal.open('mxm.modal', paymentCardForm, 'small');
};

  return (
    <div>
      <div className="font-bold pb-2 text-lg">Current Payment Card</div>
      <div className="flex inline items-center">
        <span>*************4256</span>
        <span className="ml-2">Exp 2024</span>
        <div className="ml-4">
          <Button size={ButtonSizes.small} variant={ButtonVariants.square} customClassName="mr-2" clickEvent={() => openPaymentModal()}>Update</Button>
          <Button size={ButtonSizes.small} variant={ButtonVariants.plain} clickEvent={() => deleteCard()}>Delete</Button>
        </div>
      </div>
      <div className="font-bold pb-2 text-lg mt-8">Active Subscription</div>
      <div className="flex inline items-center">
        <span>3.99/mo. for MXM News Plus. Renews 8/2/2023</span>
        <div className="ml-4">
          <Button size={ButtonSizes.small} variant={ButtonVariants.plain} clickEvent={() => cancelSubscription()}>Cancel</Button>
        </div>
      </div>
    </div>
  );
};

BillingSubscription.displayName = 'BillingSubscription';

export default BillingSubscription;
