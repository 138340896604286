import { ArticleSchema, TagSchema } from '@roc-digital/mxm-base/data';
import { Icon, InputSearch, Loading, ArticleView } from '@roc-digital/ui-web';
import { searchV2 } from '@roc-digital/mxm-base/logic';
import {
  useMethod,
  mapToArticleContent,
  chevronRightIcon,
} from '@roc-digital/ui-lib';
import React from 'react';
import { useNavigate } from 'react-router';
import { useAutoAnimate } from '@formkit/auto-animate/react';

export interface SearchProps { }

export function Search(props: SearchProps) {
  const searchQuery = useMethod(searchV2);
  const [query, setQuery] = React.useState('');
  const navigate = useNavigate();
  const [modalRef] = useAutoAnimate();
  const [listRef] = useAutoAnimate();

  React.useEffect(() => {
    if(!query || !query.trim()) {
      searchQuery.setResult({
        articles: [],
        tags: [],
      });

      return;
    }

    searchQuery.debounce([query.trim()], 1000)
  }, [query]);

  const hasResults = searchQuery.count > 0 && (searchQuery.result?.articles?.length || searchQuery.result?.tags?.length);

  const closeSearch = () => {
    setQuery('');
  };

  return <div className='flex flex-col relative' ref={modalRef}>
    <InputSearch
      value={query}
      changeValueEvent={setQuery}
      id={'search'}
    />

    {query ? <>
      <div ref={listRef} className='flex flex-col w-full max-h-[90vh] overflow-y-auto bg-white dark:bg-surface-dark shadow-xl' style={{
      position: 'absolute', left: 0, bottom: -30,
      borderRadius: '16px',
      boxShadow: 'rgb(0 0 0 / 10%) 0px 12px 24px -8px, 0px -1px 3px 0px #00000017',
      gap: '19px',
      padding: '36px',
      minWidth: '393px',
      top: '58px',
      minHeight: '100px',
      height: 'fit-content',
      zIndex: 20
    }}>
      {!hasResults && !searchQuery.loading ? <>
        <span className="!font-['Pragati Narrow'] text-[20px] font-[700] flex-1 text-center">No results found</span>
      </> : <></>}
      {searchQuery.loading ? <>
        <Loading/>
      </> : <>
        <RenderArticles
          onClick={articleId => {
            setQuery('');
            navigate(`/article/${articleId}`);
          }}
          articles={searchQuery.result?.articles || []}
        />
        <RenderTags
          tags={searchQuery.result?.tags || []}
        />
      </>}
    </div>
    <div className='search-background-screen' onClick={() => closeSearch()}></div>
    </> : <></>}
  </div>;
}

interface RenderArticlesProps {
  articles: ArticleSchema[];
  onClick: (articleId: string) => void;
}

function RenderArticles(props: RenderArticlesProps) {
  const [parent] = useAutoAnimate();

  const items = React.useMemo(() => {
    return props.articles
    .slice(0, 10)
    .map(a => mapToArticleContent(a));
  }, [props.articles]);

  if(!props.articles?.length) {
    return <></>;
  }

  return <>
    <div className="flex flex-row !font-chivo items-center">
      <span className="text-action !font-extrabold !text-[24px] !leading-9 !tracking-tight flex-grow !font-chivo">Articles</span>      
    </div>
    {items.map((article, i) => {
      return <React.Fragment key={article.id}>
        {i > 0  ? <>
          <div style={{height: 1}} className='bg-[#f5f5f5] dark:bg-[#000]'></div>
        </> : <></>}
        <div className="min-height-[200px]">
          <ArticleView
            article={article}
            orientation='landscape'
            size='sm'
            noFrame
            actions={false}
            onAction={(action) => {
              if(action.action === 'open') {
                props.onClick(action.articleId);
              }
            }}
          />
        </div>
        
      </React.Fragment>;
    })}
  </>
}

interface RenderTagsProps {
  tags: TagSchema[];
}

function RenderTags(props: RenderTagsProps) {

  if(!props.tags?.length) {
    return <></>;
  }

  return <>
    <div className="flex flex-row !font-chivo items-center">
      <span className="text-action !font-extrabold !text-[24px] !leading-9 !tracking-tight flex-grow !font-chivo">Topics</span>
    </div>
    <div className='flex flex-col gap-2'>
      {props.tags.map((tag, i) => {
        return <div key={tag.id} className="flex items-center cursor-pointer">
          <span className="!font-['Pragati Narrow'] text-[20px] font-[700] flex-1">{tag.name}</span>
          <Icon src={chevronRightIcon} fill="#919193" size='small'/>
        </div>;
      })}
    </div>
  </>
}